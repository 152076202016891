import React from "react";
import { BUTTON_TITLE, politicalAffiliationsOptions, FIELD_TYPES, NEW_CLIENT_TABS } from "../../../utils/constants";
import { FORM_LABELS, FORM_PLACEHOLDERS, FORM_NAMES } from "../../../utils/constants";
import { LegalAndOtherRecordsProps } from "../../../utils/types";
import { ActionButton } from "../../ActionButton";
import SelectComponent from "../../SelectComponent";
import TextInput from "../../TextInput";

const LegalAndOtherRecords: React.FC<LegalAndOtherRecordsProps> = (props) => {
  const { values, errors, setFieldValue, handleChange, resetForm, handleTabChange } = props;

  return (
    <>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.LEGAL_RECORDS}
          name={FORM_NAMES.LEGAL_RECORDS}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.legal_other_records}
          onChange={handleChange}
          errorMessage={errors.legal_other_records}
        />
        <SelectComponent
          label={FORM_LABELS.POLITICAL_AFFILIATIONS}
          name={FORM_NAMES.POLITICAL_AFFILIATIONS}
          items={politicalAffiliationsOptions}
          value={values.licenses_or_credentials}
          onChange={(e) => setFieldValue(FORM_NAMES.LICENSES_OR_CREDENTIALS, e.target.value)}
          errorMessage={errors.licenses_or_credentials}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.ACADEMIC_RECORDS}
          name={FORM_NAMES.ACADEMIC_RECORDS}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.academic_records}
          onChange={handleChange}
          errorMessage={errors.academic_records}
        />
        <TextInput
          label={FORM_LABELS.CRIMINAL_RECORDS}
          name={FORM_NAMES.CRIMINAL_RECORDS}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.criminal_records}
          onChange={handleChange}
          errorMessage={errors.criminal_records}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.TESTIMONY_TRANSCRIPTS}
          name={FORM_NAMES.TESTIMONY_TRANSCRIPTS}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.testimony_transcripts}
          onChange={handleChange}
          errorMessage={errors.testimony_transcripts}
        />

        <TextInput
          label={FORM_LABELS.PRIOR_STATEMENTS_OR_REPORTS}
          name={FORM_NAMES.PRIOR_STATEMENTS_OR_REPORTS}
          value={values.prior_statements_or_reports}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          onChange={handleChange}
          isSwitch
          checked={values?.prior_statements_or_reports_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.PRIOR_STATEMENTS_OR_REPORTS_CHECK, e.target.checked)}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.WITNESS_PROTECTION_PROGRAM}
          name={FORM_NAMES.WITNESS_PROTECTION_PROGRAM}
          value={values.witness_protection_program}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          onChange={handleChange}
          isSwitch
          checked={values?.witness_protection_program_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.WITNESS_PROTECTION_PROGRAM_CHECK, e.target.checked)}
        />
        <TextInput
          label={FORM_LABELS.NO_CONTACT_OR_RESTRAINING_ORDER}
          name={FORM_NAMES.NO_CONTACT_OR_RESTRAINING_ORDER}
          value={values.no_contact_or_restraining_order}
          placeholder={FORM_PLACEHOLDERS.DASHED}
          onChange={handleChange}
          isSwitch
          checked={values?.no_contact_or_restraining_order_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.NO_CONTACT_OR_RESTRAINING_ORDER_CHECK, e.target.checked)}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
        <TextInput
          label={FORM_LABELS.PRIOR_VERDICTS_OR_SETTLEMENTS}
          name={FORM_NAMES.PRIOR_VERDICTS_OR_SETTLEMENTS}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          value={values.prior_verdicts_or_settlements}
          onChange={handleChange}
          errorMessage={errors.prior_verdicts_or_settlements}
          isSwitch
          checked={values?.prior_verdicts_or_settlements_check}
          onSwitchChange={(e) => setFieldValue(FORM_NAMES.PRIOR_VERDICTS_OR_SETTLEMENTS_CHECK, e.target.checked)}
        />
        <TextInput
          label={FORM_LABELS.ADDITIONAL_INFORMATION}
          name={FORM_NAMES.ADDITIONAL_INFORMATION}
          value={values.additionalInformation}
          onChange={handleChange}
          errorMessage={errors.additionalInformation}
          type={FIELD_TYPES.TEXT_AREA}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          onClick={() => {
            handleTabChange?.(NEW_CLIENT_TABS[3]);
            resetForm?.();
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            handleTabChange?.(NEW_CLIENT_TABS[1]);
            resetForm?.();
          }}
          className='cancel-button'
        />
      </div>
    </>
  );
};

export default LegalAndOtherRecords;
