// src/components/EditCandidate.tsx

import React, { useCallback, useState } from "react";
import { FORM_LABELS, SORT_DIRECTIONS } from "../../../../utils/constants";
import { inputFieldsArray, PariticpantInitialTabs } from "../../../../utils/constants/formFieldsJson";
import {
  addFieldToInputFieldsData,
  createNewTab,
  dragFieldInTab,
  filterInputFieldsData,
  moveFieldInTab,
  removeFieldFromTab,
  updateTabContent,
  updateTabsWithNewTab
} from "../../../../utils/helpers/customizableTabHelper";
import { ConfigureTabProps, EditCandidateProps, InputField } from "../../../../utils/types";
import CustomizableTabs from "../../../CustomizableTabs";
import ToggleButton from "../../../ToggleButton/toggleButton";

const CustomizableEditCandidate: React.FC<EditCandidateProps> = () => {
  const [tabs, setTabs] = useState<ConfigureTabProps[]>(PariticpantInitialTabs);
  const [inputFieldsData, setInputFieldsData] = useState<InputField[]>(inputFieldsArray);
  const [isConfigureMode, setIsConfigureMode] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(tabs[0]);

  const addNewTab = useCallback(
    (label: string) => {
      const newTab = createNewTab(tabs, label);
      setTabs((prevTabs) => updateTabsWithNewTab(prevTabs, newTab));
    },
    [tabs]
  );

  const handleFieldChange = useCallback((updatedTab: ConfigureTabProps) => {
    setTabs((prevTabs) => updateTabContent(prevTabs, updatedTab));
    setInputFieldsData((prevInputFieldsData) => filterInputFieldsData(prevInputFieldsData, updatedTab));
  }, []);

  const onMoveDown = useCallback(
    (field: InputField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.DOWN, field.name));
    },
    [activeTab]
  );

  const onMoveUp = useCallback(
    (field: InputField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.UP, field.name));
    },
    [activeTab]
  );

  const handleRemoveField = useCallback(
    (field: InputField) => {
      setInputFieldsData((prevInputFieldsData) => addFieldToInputFieldsData(prevInputFieldsData, field));
      setTabs((prevTabs) => removeFieldFromTab(prevTabs, activeTab?.value ?? "", field.name));
    },
    [activeTab]
  );

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const handleConfigureModeChange = useCallback((checked: boolean) => {
    setIsConfigureMode(checked);
  }, []);

  const moveField = useCallback(
    (dragIndex: number, hoverIndex: number, field: InputField) => {
      setTabs((prevTabs) => dragFieldInTab(prevTabs, activeTab?.value ?? "", dragIndex, hoverIndex, field));
    },
    [activeTab]
  );

  const onFormSubmit = (tab: ConfigureTabProps) => {
    console.log("Edit Candidate: TAB - ", tab);
  };

  return (
    <div>
      <ToggleButton
        label={FORM_LABELS.CONFIGURE_MODE}
        isChecked={isConfigureMode}
        handleChange={handleConfigureModeChange}
      />
      <CustomizableTabs
        tabs={tabs}
        activeTab={activeTab}
        isConfigureMode={isConfigureMode}
        inputFieldsData={inputFieldsData}
        onTabChange={handleTabChange}
        addNewTab={addNewTab}
        handleFieldChange={handleFieldChange}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        handleRemoveField={handleRemoveField}
        initialValues={{}}
        validationSchema={{}}
        moveField={moveField}
        onFormSubmit={onFormSubmit}
      />
    </div>
  );
};

export default CustomizableEditCandidate;
