import { PSLInstanceClass } from "../pslInstance";
const PSL = PSLInstanceClass.getPSLInstance();

class Config {
  private static instance: Config;
  private initialized: boolean = false;

  // Default values
  SERVER_URL: string = "https://stg-server.anerva.net";

  private constructor() {}

  public static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }

    return Config.instance;
  }

  public async initialize() {
    if (this.initialized) {
      return;
    }

    this.SERVER_URL = await PSL.GetSetting("host-api", this.SERVER_URL);
    console.log("Server URL", this.SERVER_URL);

    this.initialized = true;
  }
}

export const config = Config.getInstance();
export async function initializeConfig() {
  await config.initialize();
}
