import React from "react";
import { NavigationTabProps, TabItem } from "../../utils/types";
import "./style.navigationtabs.scss";

const NavigationTab: React.FC<NavigationTabProps> = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className='navigation-tab'>
      {tabs.map((tab: TabItem, index: number) => (
        <div
          key={index}
          className={`tab-item ${activeTab === tab.id ? "active" : ""} ${tab.status === "completed" ? "bg-green-100 border-left-primary-light" : ""}`}
          onClick={() => setActiveTab(tab.id)}
        >
          <span className='tab-label'>{tab.label}</span>
        </div>
      ))}
    </div>
  );
};

export default NavigationTab;
