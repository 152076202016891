import { CaseStatuses, variants } from "../../utils/constants";

export const getCaseCardVariant = (activeTabValue?: string) =>
  activeTabValue === CaseStatuses[4].value
    ? variants.INFO
    : activeTabValue === CaseStatuses[0].value
      ? variants.WARNING
      : activeTabValue === CaseStatuses[1].value
        ? variants.SUCCESS
        : activeTabValue === CaseStatuses[2].value
          ? variants.DANGER
          : variants.INFO;
