import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Participant, ParticipantsState, SelectedParticipantProps } from "../../utils/types";

const initialState: ParticipantsState = {
  candidates: [],
  witnesses: [],
  participants: [],
  experts: [],
  trialTeam: [],
  selectedParticipant: null
};

const participantsSlice = createSlice({
  name: "participants",
  initialState,
  reducers: {
    fetchWitnesses: (state, action: PayloadAction<SelectedParticipantProps[]>) => {
      state.witnesses = action.payload;
      if (state.selectedParticipant) {
        const updatedParticipant = action.payload.find((p) => p.id === state.selectedParticipant?.id);
        if (updatedParticipant) {
          state.selectedParticipant = updatedParticipant;
        }
      }
    },
    fetchExperts: (state, action: PayloadAction<SelectedParticipantProps[]>) => {
      state.experts = action.payload;
      if (state.selectedParticipant) {
        const updatedParticipant = action.payload.find((p) => p.id === state.selectedParticipant?.id);
        if (updatedParticipant) {
          state.selectedParticipant = updatedParticipant;
        }
      }
    },
    fetchCandidates: (state, action: PayloadAction<SelectedParticipantProps[]>) => {
      state.candidates = action.payload;
      if (state.selectedParticipant) {
        const updatedParticipant = action.payload.find((p) => p.id === state.selectedParticipant?.id);
        if (updatedParticipant) {
          state.selectedParticipant = updatedParticipant;
        }
      }
    },
    addCandidate: (state, action: PayloadAction<Participant>) => {
      state.candidates = [{ ...action.payload }, ...state.candidates];
    },
    deleteCandidate: (state, action: PayloadAction<Participant>) => {
      state.candidates = state.candidates.filter((candidate) => candidate.email !== action.payload.email);
    },
    addWitness: (state, action: PayloadAction<Participant>) => {
      state.witnesses = [{ ...action.payload }, ...state.witnesses];
    },
    deleteWitness: (state, action: PayloadAction<Participant>) => {
      state.witnesses = state.witnesses.filter((witness) => witness.firstName !== action.payload.firstName);
    },
    addParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants = [{ ...action.payload }, ...state.participants];
    },
    deleteParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants = state.participants.filter(
        (participant) => participant.firstName !== action.payload.firstName
      );
    },
    addExpert: (state, action: PayloadAction<Participant>) => {
      state.experts = [{ ...action.payload }, ...state.experts];
    },
    deleteExpert: (state, action: PayloadAction<Participant>) => {
      state.experts = state.experts.filter((expert) => expert.firstName !== action.payload.firstName);
    },
    setSelectedParticipant: (state, action: PayloadAction<SelectedParticipantProps | null>) => {
      state.selectedParticipant = action.payload;
    },
    clearSelectedParticipant: (state) => {
      state.selectedParticipant = null;
    },

    setTrialTeamParticipants: (state, action: PayloadAction<Participant[]>) => {
      state.trialTeam = action.payload;
    }
  }
});

const participantsPersistConfig = {
  key: "participants",
  storage,
  whitelist: ["selectedParticipant", "trialTeam"] // only selectedParticipant will be persisted
};
const persistedParticipantsReducer = persistReducer(participantsPersistConfig, participantsSlice.reducer);
// Exporting the actions
export const {
  addCandidate,
  deleteCandidate,
  addWitness,
  deleteWitness,
  addParticipant,
  deleteParticipant,
  addExpert,
  deleteExpert,
  fetchCandidates,
  fetchExperts,
  fetchWitnesses,
  setSelectedParticipant,
  clearSelectedParticipant,
  setTrialTeamParticipants
} = participantsSlice.actions;

// Exporting the reducer
export default persistedParticipantsReducer;
