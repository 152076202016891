import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { SOCKET_EVENTS } from "../../../utils/constants/socketEvents";
import { ClientInformationFormValues, ParticipantPayload, SocketEmitFunction } from "../../../utils/types";

/**
 * Helper function to create payload for saving participant details
 * @param formValues - The form values for the participant
 * @param userId - The user ID
 * @param caseId - The case ID
 * @param participantId - The participant ID
 * @returns Payload object
 */
export const createParticipantPayload = (
  formValues: ClientInformationFormValues,
  userId: string,
  caseId?: string,
  participantId?: string
): ParticipantPayload => {
  return {
    caseId: caseId || "",
    participantId: participantId || "",
    userId: userId || "",
    firstName: formValues?.firstName || "",
    lastName: formValues?.lastName || "",
    nickname: formValues?.nickname || "",
    preferredTitle: formValues?.preferredTitle || "",
    pronouns: formValues?.pronouns || "",
    age: formValues?.age || "",
    sex: formValues?.sex || "",
    gender: formValues?.gender || "",
    occupation: formValues?.occupation || "",
    address: formValues?.address || "",
    isHomeOwner: false,
    ethnicity: "",
    relationshipStatus: formValues?.relationshipStatusOption || "",
    timeZone: formValues?.timeZone || "",
    additionalLegalInfo: formValues?.additionalInformation || "",
    preferredContactMethod: "",
    lengthOfResidency: 0,
    householdIncome: 0,
    educationLevel: formValues?.educationLevel || "",
    hobbies: [],
    addressAdditional: formValues?.addressAdditional || "",
    city: formValues?.city || "",
    state: formValues?.state || "",
    country: formValues?.country || "",
    zipCode: formValues?.zipCode || "",
    children: formValues?.children || false,
    childrenText: formValues?.childrenText || "",
    childSupport: formValues?.childSupport || false,
    childSupportText: formValues?.childSupportText || "",
    email: formValues?.email || "",
    phoneNumber: formValues?.phoneNumber || "",
    eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT_DETAILS,
    type: SOCKET_EVENTS.PARTICIPANT_AND_DETAILS_UPDATED,
    requestId: uuidv4()
  };
};
/**
 * Helper function to emit socket events to save participant details
 * @param socketEmit - The socket emit function
 * @param payload - The payload containing participant details
 */
export const emitSaveParticipantDetails = (socketEmit: SocketEmitFunction, payload: ParticipantPayload) => {
  try {
    if (socketEmit) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        payload,
        ({ success, message }: { success: boolean; message: string }) => {
          if (success) toast.success(message);
        },
        ({ success, message }: { success: boolean; message: string }) => {
          if (!success) toast.error(message);
        }
      );
    }
  } catch (error) {
    if (error instanceof Error) console.log("emitSaveParticipantDetails error: ", error?.message);
  }
};
