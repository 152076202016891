import { Form, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/types";
import { ClientInformationFormValues, NewClientFormBasicProps } from "../../../../../utils/types";
import BasicInfo from "../../../BasicInfo";

const NewClientFormBasic: React.FC<NewClientFormBasicProps> = (props) => {
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm, setValues, isValid } =
    useFormikContext<ClientInformationFormValues>();

  const { selectedParticipant } = useSelector((state: RootState) => state.participants);

  useEffect(() => {
    if (selectedParticipant) {
      const details = selectedParticipant.participantDetails?.[0] ?? {};
      setValues((prevValues) => ({
        ...prevValues,
        // Fields from the main selectedParticipant object
        firstName: selectedParticipant.firstName ?? prevValues.firstName,
        lastName: selectedParticipant.lastName ?? prevValues.lastName,
        email: selectedParticipant.email ?? prevValues.email,
        phoneNumber: selectedParticipant.phoneNumber ?? prevValues.phoneNumber,

        city: selectedParticipant.city ?? prevValues.city,

        // Fields from participantDetails
        nickname: details.nickname ?? prevValues.nickname,
        age: (details?.age || "")?.toString() ?? (prevValues.age || ""),
        sex: details.sex ?? prevValues.sex,
        gender: details.gender ?? prevValues.gender,
        pronouns: details.pronouns ?? prevValues.pronouns,
        preferredTitle: details.preferredTitle ?? prevValues.preferredTitle,
        occupation: details.occupation ?? prevValues.occupation,
        educationLevel: details.educationLevel ?? prevValues.educationLevel,
        address: details.address ?? prevValues.address,
        addressAdditional: details.addressAdditional ?? prevValues.addressAdditional,
        children: details.anyChildren ?? prevValues.children,
        childrenText: details.childrenText ?? prevValues.childrenText,
        childSupport: details.doesClientPayChildSupport ?? prevValues.childSupport,
        childSupportText: details.childSupportText ?? prevValues.childSupportText,
        relationshipStatus: details.relationshipStatus ?? prevValues.relationshipStatus,
        timeZone: details.timeZone ?? prevValues.timeZone,

        // Additional fields from participantDetails
        ethnicity: details.ethnicity ?? prevValues.ethnicity,
        lengthOfResidency: details.lengthOfResidency?.toString() ?? prevValues.lengthOfResidency,
        householdIncome: details.householdIncome?.toString() ?? prevValues.householdIncome,
        isHomeOwner: details.isHomeOwner ?? prevValues.isHomeOwner,
        criminalRecords: details.criminalRecords ?? prevValues.criminalRecords,
        pastLawsuits: details.pastLawsuits ?? prevValues.pastLawsuits,
        additionalLegalInfo: details.additionalLegalInfo ?? prevValues.additionalLegalInfo,
        uploadedPhotos: details.uploadedPhotos ?? prevValues.uploadedPhotos,
        uploadedVideos: details.uploadedVideos ?? prevValues.uploadedVideos,
        hobbies: details.hobbies ?? prevValues.hobbies,
        organizationsAffiliatedWith: details.organizationsAffiliatedWith ?? prevValues.organizationsAffiliatedWith,
        socialInterests: details.socialInterests ?? prevValues.socialInterests,
        preferredContactMethod: details.preferredContactMethod ?? prevValues.preferredContactMethod,
        canReceiveMarketingEmails: details.canReceiveMarketingEmails ?? prevValues.canReceiveMarketingEmails,
        socialMediaProfiles: details.socialMediaProfiles ?? prevValues.socialMediaProfiles,

        // Fields that are not in participantDetails remain unchanged
        state: selectedParticipant?.state || prevValues.state,
        country: selectedParticipant?.country || prevValues.country,
        zipCode: selectedParticipant?.zipCode || prevValues.zipCode,
        relationshipStatusOption: details.relationshipStatusOption ?? prevValues.relationshipStatusOption,
        additionalInformation: selectedParticipant?.additionalInformation || prevValues.additionalInformation
      }));
    }
  }, [selectedParticipant, setValues]);

  return (
    <Form onSubmit={handleSubmit}>
      <BasicInfo
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        photoLabel={props?.photoLabel}
        handleTabChange={props?.handleTabChange}
        resetForm={resetForm}
        isValid={isValid}
      />
    </Form>
  );
};

export default NewClientFormBasic;
