import * as Yup from "yup";
import { JurorInformationFormValues } from "../../../../../utils/types";

export const initialValues: JurorInformationFormValues = {
  firstName: "",
  lastName: "",
  nickname: "",
  age: "",
  sex: "",
  gender: "",
  pronouns: "",
  preferredTitle: "",
  address: "",
  addressAdditional: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  children: false,
  childrenText: "",
  childSupport: false,
  childSupportText: "",
  relationshipStatusOption: "",
  relationshipStatus: "",
  phoneNumber: "",
  altPhoneNumber: "",
  email: "",
  anyMedicalConditions: false,
  medicalConditionText: "",
  jurorID: "",
  timeZone: ""
};

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  pronouns: Yup.string().optional(),
  preferredTitle: Yup.string().optional(),
  sex: Yup.string().optional(),
  gender: Yup.string().optional(),
  occupation: Yup.string().optional(),
  educationLevel: Yup.string().optional(),
  age: Yup.number().optional().positive().integer(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  country: Yup.string().optional(),
  zipCode: Yup.string().optional(),
  phoneNumber: Yup.string().optional(),
  altPhoneNumber: Yup.string().optional(),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  anyMedicalConditions: Yup.boolean().optional(),
  medicalConditionText: Yup.string().optional(),
  jurorID: Yup.string().optional(),
  timeZone: Yup.string().optional(),
  relationshipStatus: Yup.string().optional()
});
