import { Form, useFormikContext } from "formik";
import React, { useCallback, useState } from "react";
import { BUTTON_TITLE, FORM_LABELS, FORM_NAMES, FORM_PLACEHOLDERS } from "../../../../utils/constants";
import { CreateDocumentsFormProps, DocumentFormValues } from "../../../../utils/types";
import { ActionButton } from "../../../ActionButton";
import FileUploader from "../../../FileUploader";
import TextInput from "../../../TextInput";

const CreateDocumentForm: React.FC<CreateDocumentsFormProps> = ({ onClose, onAddDocument }) => {
  const { values, errors, handleChange } = useFormikContext<DocumentFormValues>();
  const [files, setFiles] = useState<File[] | null>([]);

  const handleFormSubmit = useCallback(() => {
    const newDocument = {
      documentName: values.documentName,
      files: files || null
    };
    onAddDocument?.(newDocument);
    onClose?.();
  }, [files, onAddDocument, onClose, values]);

  return (
    <Form>
      <TextInput
        label={FORM_LABELS.DOCUMENT_NAME}
        name={FORM_NAMES.DOCUMENT_NAME}
        type='text'
        errorMessage={errors?.documentName}
        value={values?.documentName}
        placeholder={FORM_PLACEHOLDERS.DEFAULT}
        onChange={handleChange}
      />
      <div className='w-100'>
        <FileUploader
          multiple
          onFileChange={(file) => {
            if (file) setFiles(file);
          }}
          files={files}
        />
      </div>

      <div className='d-flex align-items-center justify-content-center'>
        <ActionButton
          title={BUTTON_TITLE.ADD_DOCUMENT}
          className='save-button'
          disabled={false}
          onClick={handleFormSubmit}
        />
      </div>
    </Form>
  );
};

export default CreateDocumentForm;
