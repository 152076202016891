import React from "react";
import { StatisticsCardContainer, DonughtChart, BarChart } from "../../..";
import { STRINGS, trailStatisticsCards } from "../../../../utils/constants";
import { donutChartData, barChartData } from "../../../../utils/constants";
import DashboardCards from "../../../DashboardCard";
import "./styles.trialTeamStatistics.scss";

type TrialTeamStatisticsProps = unknown;

const TrialTeamStatistics: React.FC<TrialTeamStatisticsProps> = () => {
  return (
    <div className='trail-statistics-page'>
      <h5>{STRINGS.TRIAL_TEAM_STATISTICS}</h5>

      <div className='cards-row'>
        {trailStatisticsCards.map((card, index) => {
          return (
            <DashboardCards
              key={index.toString()}
              title={card.title}
              icon={<i className={`${card.icon} ${card.className}`}></i>}
              count={card?.count}
              className={card?.className}
            />
          );
        })}
      </div>

      <div className='flex-row'>
        <StatisticsCardContainer headerText='Total Sales' numberWithPercentage='50% 30% 10%'>
          <DonughtChart data={donutChartData} />
        </StatisticsCardContainer>

        <StatisticsCardContainer headerText='Age' numberWithPercentage='20% 30% 10%'>
          <BarChart data={barChartData} horizontal={true} />
        </StatisticsCardContainer>

        <StatisticsCardContainer headerText='Party Affiliation' numberWithPercentage='50% 30% 10%'>
          <BarChart data={barChartData} horizontal={false} />
        </StatisticsCardContainer>

        <StatisticsCardContainer headerText='Total Sales' numberWithPercentage='50% 30% 10%'>
          <DonughtChart data={donutChartData} />
        </StatisticsCardContainer>

        <StatisticsCardContainer headerText='Age' numberWithPercentage='20% 30% 10%'>
          <BarChart data={barChartData} horizontal={true} />
        </StatisticsCardContainer>

        <StatisticsCardContainer headerText='Party Affiliation' numberWithPercentage='50% 30% 10%'>
          <BarChart data={barChartData} horizontal={false} />
        </StatisticsCardContainer>
      </div>
    </div>
  );
};

export default TrialTeamStatistics;
