import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import casesSlices from "../slices/casesSlices";
import documentsSlice from "../slices/documentsSlice";
import jurorSlice from "../slices/jurorSlice";
import manageCaseSlice from "../slices/manageCaseSlice";
import menuSlice from "../slices/menuActionsSlice";
import participantsSlice from "../slices/participantsSlice";

export const store = configureStore({
  reducer: {
    manageCase: manageCaseSlice,
    documents: documentsSlice,
    juror: jurorSlice,
    cases: casesSlices,
    participants: participantsSlice,
    menu: menuSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"]
      }
    })
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
