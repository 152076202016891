import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import "./styles.scss";
import { SearchInputProps, SuggestionSelectedPayload, SuggestionsFetchRequestPayload } from "../../../utils/types";

type SuggestionValue = string;
interface CustomRenderInputComponentProps extends Autosuggest.RenderInputComponentProps {
  key?: React.Key;
}
const SearchInput = ({
  handleSearch,
  searchPlaceholder = "",
  shouldRenderSuggestions = true,
  inputClassName
}: SearchInputProps) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [searchStr, setSearchStr] = useState("");

  const handleChangeAutoSuggestInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchStr(newValue);
    if (handleSearch) {
      handleSearch(newValue);
    }
  };

  const onSuggestionSelected = (event: React.FormEvent, { suggestionValue, method }: SuggestionSelectedPayload) => {
    setSearchStr(suggestionValue ?? "");
    if (method === "click") {
      onSubmitForm(event as React.FormEvent<HTMLFormElement>, suggestionValue);
    }
  };

  const onSuggestionsFetchRequested = ({ value }: SuggestionsFetchRequestPayload) => {
    if (shouldRenderSuggestions) {
      getSuggestions(value);
    }
  };

  const onSuggestionsClearRequested = () => {
    if (shouldRenderSuggestions) {
      getSuggestions("");
    }
  };

  const getSearchHistory = () => {
    const history = JSON.parse(localStorage.getItem("searchHistory") || "[]");
    return history;
  };

  const addSearchHistory = (search: string) => {
    let history = JSON.parse(localStorage.getItem("searchHistory") || "[]");
    history = history.filter((item: string) => item !== search);
    history.unshift(search);
    if (history.length > 10) {
      history.length = 10;
    }
    localStorage.setItem("searchHistory", JSON.stringify(history));
  };

  const getSuggestions = (value: SuggestionValue) => {
    const list = getSearchHistory();
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return setSuggestions(
      inputLength === 0 ? list : list.filter((lang: string) => lang.toLowerCase().slice(0, inputLength) === inputValue)
    );
  };

  const onClickClearSearch = () => {
    setSearchStr("");
    if (handleSearch) {
      handleSearch("");
    }
  };

  const onSubmitForm = (event?: React.FormEvent<HTMLFormElement>, _searchStr?: string) => {
    const searchTxt = _searchStr ?? searchStr;
    if (searchTxt.length > 0) {
      if (event) {
        event.preventDefault();
      }
      const _searchTxt = searchTxt.replaceAll("/", " ");
      addSearchHistory(_searchTxt);
      if (handleSearch) {
        handleSearch(_searchTxt);
      }
    }
  };

  return (
    <form className='app-search' onSubmit={onSubmitForm}>
      <div className='position-relative'>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={(item: string) => item}
          onSuggestionSelected={onSuggestionSelected}
          shouldRenderSuggestions={(value: string, reason: unknown) => {
            return (
              shouldRenderSuggestions && (value.trim().length > 0 || reason === "input-focused" || reason === "render")
            );
          }}
          renderSuggestion={(item: string, { isHighlighted }: { isHighlighted: boolean }) => {
            return (
              <div
                style={{
                  background: isHighlighted ? "lightgray" : "white",
                  padding: "5px",
                  cursor: "pointer"
                }}
              >
                {item}
              </div>
            );
          }}
          renderSuggestionsContainer={({
            containerProps,
            children
          }: {
            containerProps: React.HTMLProps<HTMLDivElement>;
            children: React.ReactNode;
          }) => {
            const { key, ...rest } = containerProps; // Remove `key`
            return (
              shouldRenderSuggestions && (
                <div key={key} {...rest}>
                  {children}
                </div>
              )
            );
          }}
          inputProps={{
            className: `form-control search-autocomplete ${inputClassName}`,
            id: `autosuggest-search`,
            name: "autosuggest-search",
            value: searchStr,
            placeholder: searchPlaceholder,
            onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
              if (e.target?.value?.length > 0) {
                e.target.select();
              }
            },
            onChange: (event: React.FormEvent<HTMLElement>, { newValue }: { newValue: string }) => {
              handleChangeAutoSuggestInput({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
            }
          }}
          renderInputComponent={(inputProps: CustomRenderInputComponentProps) => {
            const { key, ref, ...rest } = inputProps;
            return (
              <div key={key}>
                <input {...rest} ref={ref} />
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmitForm();
                  }}
                >
                  <span className='fa fa-regular fa-magnifying-glass'></span>
                </a>
                {inputProps.value?.length > 0 && (
                  <a onClick={onClickClearSearch}>
                    <span className='fa fa-regular fa-close block close-icon'></span>
                  </a>
                )}
              </div>
            );
          }}
        />
      </div>
    </form>
  );
};

export default SearchInput;
