import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocument } from "../../../../redux/slices/documentsSlice";
import { RootState } from "../../../../redux/types";
import { CaseDocumentProps, CaseEvidence } from "../../../../utils/types";
import CaseDocuments from "../CaseDocuments";

const Documents: React.FC<CaseDocumentProps> = () => {
  const dispatch = useDispatch();
  const documents = useSelector((state: RootState) => state.documents.documents);

  const handleDownload = useCallback((data: CaseEvidence) => {
    alert(`Downloading document with id: ${data?.id}`);
  }, []);

  const handleDelete = useCallback(
    (data: CaseEvidence) => {
      dispatch(deleteDocument(data?.id || 0));
    },
    [dispatch]
  );

  return <CaseDocuments documentsList={documents} onDownload={handleDownload} onDelete={handleDelete} />;
};

export default Documents;
