import { ColDef, ValueGetterParams, ICellRendererParams } from "ag-grid-community";
import { STRINGS } from "../../../../utils/constants";

export const toggleField = (
  field: string,
  participantType: string,
  activeFields: Record<string, boolean>,
  setActiveFields: (
    fields: Record<string, boolean> | ((prevFields: Record<string, boolean>) => Record<string, boolean>)
  ) => void,
  columnDefs: ColDef[],
  setColumnDefs: (defs: ColDef[]) => void,
  dummyValues: Record<string, unknown>
) => {
  const fieldKey = field === STRINGS.Education ? STRINGS.EducationLevel : field.toLowerCase();

  setActiveFields((prevFields: Record<string, boolean>) => {
    const updatedFields = { ...prevFields, [field]: !prevFields[field] };
    return updatedFields;
  });

  if (!activeFields[field]) {
    const newColumn: ColDef = {
      field: fieldKey,
      headerName: field,
      sortable: true,
      minWidth: 140,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      valueGetter: (params: ValueGetterParams) => {
        const colId = params.column?.getColId();
        return colId ? params.data?.participantDetails[0]?.[colId] || dummyValues[colId] : undefined;
      },
      cellRenderer: (params: ICellRendererParams) => <span>{params.value}</span>
    };
    setColumnDefs([...columnDefs, newColumn]);
  } else {
    setColumnDefs(columnDefs.filter((colDef) => colDef.field !== fieldKey));
  }
};
