import { ColDef, ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useState } from "react";
import { ActionButton, BreadCrumbs } from "../../components";
import AgGrid from "../../components/AgGrid";
import ActionsRenderer from "../../components/AgGrid/ActionGridRenderer";
import NavigationWrapper from "../../components/NavigationWrapper";
import { CreateCaseDocument } from "../../components/Pages/CreateCase";
import WrapperComponent from "../../components/WrapperComponent";
import { ACTION_COLUMN_STYLE, baseDocsColumns, BUTTON_TITLE, caseDocsGridData, TITLES } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { transformFileToDocumentData } from "../../utils/transformers";
import { DocumentFormValues } from "../../utils/types";
import { DocumentData } from "../../utils/types/index";

const Documents = () => {
  const [documentModalShow, setDocumentModalShow] = useState<boolean>(false);
  const [documentsData, setDocumentsData] = useState<DocumentData[]>(caseDocsGridData);

  const handleDeleteDocument = useCallback((docToDelete: DocumentData) => {
    setDocumentsData((prevData) => prevData.filter((doc) => doc.documentName !== docToDelete.documentName));
  }, []);

  const DocumentsColumns: ColDef[] = [
    ...baseDocsColumns,
    {
      ...ACTION_COLUMN_STYLE,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer editable={false} data={params.data} onDelete={() => handleDeleteDocument(params.data)} />
      )
    }
  ];

  const handleAddDocument = useCallback((newDocument: DocumentFormValues) => {
    const modifiedDocs: DocumentData[] = [];
    if (newDocument?.files && newDocument.files.length > 0) {
      const transformedDocs = newDocument.files.map((file: File) =>
        transformFileToDocumentData(file, newDocument.caseName, newDocument.documentName)
      );
      modifiedDocs.push(...transformedDocs);
    }
    setDocumentsData((prevData) => [...modifiedDocs, ...prevData]);
    setDocumentModalShow(false);
  }, []);

  return (
    <div className='d-flex flex-column w-100'>
      <BreadCrumbs
        breadcrumbItems={[
          { text: "Create New Case", url: routes.CREATE_CASE },
          { text: "Documents", url: routes.DOCUMENTS }
        ]}
      />
      <NavigationWrapper
        title={TITLES.DOCUMENTS}
        headerChild={
          <div className='d-flex flex-row justify-content-between align-items-center gap-1'>
            <ActionButton
              className='bg-blue  px-3'
              title={BUTTON_TITLE.ADD_NEW_DOC}
              onClick={() => {
                setDocumentModalShow(true);
              }}
              icon={<i className='fa-solid fa-plus'></i>}
            />
            <ActionButton
              className='bg-primary-light '
              title={BUTTON_TITLE.DOWNLOAD_DOCUMENTS}
              onClick={() => alert("Download")}
              icon={<i className='fa-regular fa-layer-group'></i>}
            />
            <ActionButton
              title={BUTTON_TITLE.SHARE}
              className='bg-orange '
              onClick={() => alert("Share Docs")}
              icon={<i className='fa-light fa-arrow-up-from-square'></i>}
            />
          </div>
        }
      >
        <WrapperComponent>
          <div className='d-flex' style={{ height: "60vh" }}>
            <AgGrid columnDefs={DocumentsColumns} rowData={documentsData} />
          </div>
        </WrapperComponent>
      </NavigationWrapper>
      {documentModalShow && (
        <CreateCaseDocument
          showModal={documentModalShow}
          setShowModal={(value: boolean) => setDocumentModalShow(value)}
          onAddDocument={handleAddDocument}
        />
      )}
    </div>
  );
};

export default Documents;
