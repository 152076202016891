import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "./styles.PersonInfoModal.scss";
import { DonughtChart } from "..";
import { Person1 } from "../../assets/images/index";
import { InstagramIcon } from "../../assets/images/index";
import { EmailSVG, FaceBookSVG, PhoneSVG, LinkedInSVG } from "../../assets/jsxIcons";
import { donutChartData, STRINGS } from "../../utils/constants";
import { PersonInfoModalProps } from "../../utils/types";
import StatisticsCardContainer from "../Pages/TrailTeamStatistics/StatisticsCardContainer";
import EmojiValue from "./EmojiValue";

const PersonInfoModal: React.FC<PersonInfoModalProps> = ({ isOpen, toggle, data }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className='view-juror-modal'>
      <ModalBody className='view-juror-modal-body'>
        <span className='close-modal font-size-12' onClick={toggle}>
          <i className='fa-solid fa-x'></i>
        </span>

        <div className='view-juror-top'>
          <img src={Person1} alt='person' className='view-modal-img' />

          <div className='upper-left-con'>
            <div className='emojis-con'>
              <EmojiValue iconName='fa-regular fa-face-smile' value={5} />
              <EmojiValue iconName='fa-regular fa-face-smile' value={5} />
              <EmojiValue iconName='fa-regular fa-face-smile' value={5} />
            </div>
            <StatisticsCardContainer
              className='modal-statistics-card'
              headerText={STRINGS.TRIAL_TEAM_STATISTICS}
              numberWithPercentage=''
            >
              <DonughtChart data={donutChartData} conClassName='modal-donught' />
            </StatisticsCardContainer>
          </div>
        </div>

        <div className='juror-name-con'>
          <span className='font-size-18 color-primary-light fw-bold'>{data?.name}</span>
          <span className='font-size-14 '>{STRINGS.USERNAME}</span>
          <div className='vertical-divider' />
          <span className='font-size-14 '>{STRINGS.PREFIX}</span>
          <div className='vertical-divider' />
          <span className='font-size-14 '>{STRINGS.PRONOUNS}</span>
        </div>

        <div className='juror-address-field'>
          <i className='fa-light fa-location-dot'></i>
          <span className='font-size-14 ms-2'>{STRINGS.ADDRESS}</span>
        </div>

        <div className='juror-children-field'>
          <i className='fa-light fa-cloud-fog'></i>
          <span className='font-size-14 ms-2 me-2'>{STRINGS.CHILDREN}</span>
          <div className='vertical-divider' />
          <span className='font-size-14 ms-2'>{STRINGS.MARRIED}</span>
        </div>

        <div className='social-media-icons'>
          <EmailSVG className='fill-white bg-primary-light rounded-circle' />
          <PhoneSVG className='fill-white bg-primary-light rounded-circle' />
          <FaceBookSVG className='fill-white bg-icon-blue rounded-circle' />
          <img src={InstagramIcon} alt='instagram' className='insta-icon' />
          <LinkedInSVG className='fill-white bg-icon-blue rounded-circle' />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PersonInfoModal;
