import { RouteObject } from "react-router-dom";
import {
  Dashboard,
  CreateCase,
  InteractiveCalendar,
  AddNewClient,
  TrialTeam,
  JurorDetails,
  CaseEvidence,
  Documents,
  CaseList,
  ManageCase,
  EditNewClient,
  AddNewJuror,
  NotFound,
  CustomizableEditParticipant
} from "../../pages";
import routes from "./routes";

export const routesConfig: RouteObject[] = [
  { path: routes.DASHBOARD, element: <Dashboard /> },
  { path: routes.CALENDARS, element: <InteractiveCalendar /> },
  { path: routes.CREATE_CASE, element: <CreateCase /> },
  { path: routes.TRIAL_TEAM, element: <TrialTeam /> },
  { path: routes.CASE_EVIDENCE, element: <CaseEvidence /> },
  { path: routes.ADD_NEW_CANDIDATE, element: <AddNewClient /> },
  { path: routes.ADD_NEW_WITNESS, element: <AddNewClient /> },
  { path: routes.ADD_NEW_EXPERTS, element: <AddNewClient /> },
  { path: routes.ADD_NEW_PARTICIPANTS, element: <AddNewClient /> },
  { path: routes.JUROR_DETAILS, element: <JurorDetails /> },
  { path: routes.DOCUMENTS, element: <Documents /> },
  { path: routes.MANAGE_CASE, element: <ManageCase /> },
  { path: routes.CASES_LIST, element: <CaseList /> },
  { path: routes.EDIT_NEW_CANDIDATE, element: <EditNewClient /> },
  { path: routes.EDIT_NEW_WITNESS, element: <EditNewClient /> },
  { path: routes.EDIT_NEW_EXPERTS, element: <EditNewClient /> },
  { path: routes.EDIT_NEW_PARTICIPANTS, element: <EditNewClient /> },
  { path: routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, element: <CustomizableEditParticipant /> },
  { path: routes.ADD_NEW_JUROR, element: <AddNewJuror /> },

  // Fallback for any unmatched route (404)
  { path: routes.NOT_FOUND, element: <NotFound /> }
];
