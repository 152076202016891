import { useDrag, useDrop } from "react-dnd";
import { DraggableInputFieldProps } from "../../../../utils/types";
import DateInput from "../../../DateInput";
import TextInput from "../../../TextInput";
import "./draggableInputField.scss";

function DraggableInputField({
  field,
  onDragEnd,
  onDragStart,
  isConfigureMode,
  isInputField,
  moveField,
  currentIndex
}: DraggableInputFieldProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Field",
    item: field, // Pass the entire field object instead of just field.name
    canDrag: () => isConfigureMode && !field.isFixed,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const [, drop] = useDrop({
    accept: "Field",
    canDrop: () => isConfigureMode && !field.isFixed,
    hover(item: {
      index: number;
      label: string;
      name: string;
      placeholder?: string;
      isFixed?: boolean;
      type: string;
      value: Date | string | null;
      isSwitch?: boolean;
      min?: number;
      max?: number;
      id?: number;
      currencyText?: string;
    }) {
      if (item.index !== currentIndex && !item.isFixed && isConfigureMode) {
        moveField(item.index, currentIndex, item);
        item.index = currentIndex;
      }
    }
  });

  return !isInputField ? (
    <div
      onDrag={(e) => {
        if (onDragStart) onDragStart(e);
      }}
      onDragEnd={(e) => {
        if (onDragEnd) onDragEnd(e);
      }}
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className='w-100'
    >
      <div className='draggable-input-field'>
        <span className='font-size-15 ellipsis-text'>{field.label}</span>
        <i className='fa-solid fa-grip-dots-vertical'></i>
      </div>
    </div>
  ) : (
    <div
      onDrag={(e) => {
        if (onDragStart) onDragStart(e);
      }}
      onDragEnd={(e) => {
        if (onDragEnd) onDragEnd(e);
      }}
      ref={isConfigureMode ? (node) => drag(drop(node)) : null}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className='w-100'
    >
      {field.type === "date" ? (
        <DateInput
          label={field.label}
          placeholder={field.placeholder}
          parentClassName='w-100'
          value={field.value as Date}
          onDateChange={() => {}}
          minDate={new Date()}
        />
      ) : field.type !== "date" ? (
        <TextInput
          key={field.name}
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          type={field.type}
          onChange={() => {}}
          isSwitch={field.isSwitch}
          onSwitchChange={field.isSwitch ? () => {} : undefined}
          min={field.min}
          max={field.max}
        />
      ) : null}
    </div>
  );
}

export default DraggableInputField;
