export const CaseStatuses = [
  { id: 1, value: "pending", label: "Pending" },
  { id: 2, value: "completed", label: "Completed" },
  { id: 3, value: "archived", label: "Archived" },
  { id: 4, value: "closed", label: "Closed" },
  { id: 5, value: "upcoming", label: "Upcoming" }
];

export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
  { value: "prefer_not_to_say", label: "Prefer not to say" }
];

export const sexOptions = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Other", value: "other" },
  { label: "Prefer not to say", value: "prefer_not_to_say" }
];

export const timeZoneOptions = [
  { value: "Pacific/Midway", label: "Pacific/Midway (GMT-11:00)" },
  { value: "America/Adak", label: "America/Adak (GMT-10:00)" },
  { value: "Pacific/Honolulu", label: "Pacific/Honolulu (GMT-10:00)" },
  { value: "America/Anchorage", label: "America/Anchorage (GMT-09:00)" },
  { value: "America/Vancouver", label: "America/Vancouver (Pacific Time, GMT-08:00)" }, // Canada
  { value: "America/Whitehorse", label: "America/Whitehorse (Pacific Time, GMT-08:00)" }, // Canada
  { value: "America/Los_Angeles", label: "America/Los_Angeles (GMT-08:00)" },
  { value: "America/Edmonton", label: "America/Edmonton (Mountain Time, GMT-07:00)" }, // Canada
  { value: "America/Denver", label: "America/Denver (GMT-07:00)" },
  { value: "America/Regina", label: "America/Regina (Central Time, no DST, GMT-06:00)" }, // Canada
  { value: "America/Winnipeg", label: "America/Winnipeg (Central Time, GMT-06:00)" }, // Canada
  { value: "America/Chicago", label: "America/Chicago (GMT-06:00)" },
  { value: "America/Toronto", label: "America/Toronto (Eastern Time, GMT-05:00)" }, // Canada
  { value: "America/New_York", label: "America/New_York (GMT-05:00)" },
  { value: "America/Halifax", label: "America/Halifax (Atlantic Time, GMT-04:00)" }, // Canada
  { value: "America/St_Johns", label: "America/St_Johns (Newfoundland Time, GMT-03:30)" }, // Canada
  { value: "America/Sao_Paulo", label: "America/Sao_Paulo (GMT-03:00)" },
  { value: "Atlantic/South_Georgia", label: "Atlantic/South_Georgia (GMT-02:00)" },
  { value: "Atlantic/Azores", label: "Atlantic/Azores (GMT-01:00)" },
  { value: "Europe/London", label: "Europe/London (GMT+00:00)" },
  { value: "Europe/Berlin", label: "Europe/Berlin (GMT+01:00)" },
  { value: "Europe/Athens", label: "Europe/Athens (GMT+02:0)" },
  { value: "Africa/Cairo", label: "Africa/Cairo (GMT+02:00)" },
  { value: "Asia/Baghdad", label: "Asia/Baghdad (GMT+03:00)" },
  { value: "Asia/Tehran", label: "Asia/Tehran (GMT+03:30)" },
  { value: "Asia/Dubai", label: "Asia/Dubai (GMT+04:00)" },
  { value: "Asia/Kabul", label: "Asia/Kabul (GMT+04:30)" },
  { value: "Asia/Karachi", label: "Asia/Karachi (GMT+05:00)" },
  { value: "Asia/Kolkata", label: "Asia/Kolkata (GMT+05:30)" },
  { value: "Asia/Kathmandu", label: "Asia/Kathmandu (GMT+05:45)" },
  { value: "Asia/Dhaka", label: "Asia/Dhaka (GMT+06:00)" },
  { value: "Asia/Yangon", label: "Asia/Yangon (GMT+06:30)" },
  { value: "Asia/Bangkok", label: "Asia/Bangkok (GMT+07:00)" },
  { value: "Asia/Hong_Kong", label: "Asia/Hong_Kong (GMT+08:00)" },
  { value: "Asia/Tokyo", label: "Asia/Tokyo (GMT+09:00)" },
  { value: "Australia/Sydney", label: "Australia/Sydney (GMT+10:00)" },
  { value: "Pacific/Noumea", label: "Pacific/Noumea (GMT+11:00)" },
  { value: "Pacific/Auckland", label: "Pacific/Auckland (GMT+12:00)" }
];

export const educationLevelOptions = [
  { value: "none", label: "No Formal Education" },
  { value: "high school", label: "High School Diploma or Equivalent" },
  { value: "associate", label: "Associate Degree" },
  { value: "bachelor", label: "Bachelor's Degree" },
  { value: "master", label: "Master's Degree" },
  { value: "doctorate", label: "Doctorate Degree" },
  { value: "prefer_not_to_say", label: "Prefer not to say" }
];

export const preferredTitleOptions = [
  { value: "mr", label: "Mr." },
  { value: "mrs", label: "Mrs." },
  { value: "miss", label: "Miss" },
  { value: "dr", label: "Dr." },
  { value: "prefer_not_to_say", label: "Prefer not to say" },
  { value: "other", label: "Other" }
];

export const pronounsOptions = [
  { value: "he/him", label: "He/Him" },
  { value: "she/her", label: "She/Her" },
  { value: "they/them", label: "They/Them" },
  { value: "prefer_not_to_say", label: "Prefer not to say" },
  { value: "other", label: "Other" }
];

export const statesOptions = [
  { value: "AB", label: "Alberta" },
  { value: "BC", label: "British Columbia" },
  { value: "MB", label: "Manitoba" },
  { value: "NB", label: "New Brunswick" },
  { value: "NL", label: "Newfoundland and Labrador" },
  { value: "NS", label: "Nova Scotia" },
  { value: "ON", label: "Ontario" },
  { value: "PE", label: "Prince Edward Island" },
  { value: "QC", label: "Quebec" },
  { value: "SK", label: "Saskatchewan" },
  { value: "NT", label: "Northwest Territories" },
  { value: "NU", label: "Nunavut" },
  { value: "YT", label: "Yukon" }
];

export const citiesInCanada = [
  { value: "calgary", label: "Calgary", state: "AB" },
  { value: "edmonton", label: "Edmonton", state: "AB" },
  { value: "red_deer", label: "Red Deer", state: "AB" },
  { value: "lethbridge", label: "Lethbridge", state: "AB" },

  { value: "vancouver", label: "Vancouver", state: "BC" },
  { value: "victoria", label: "Victoria", state: "BC" },
  { value: "kelowna", label: "Kelowna", state: "BC" },
  { value: "surrey", label: "Surrey", state: "BC" },

  { value: "winnipeg", label: "Winnipeg", state: "MB" },
  { value: "brandon", label: "Brandon", state: "MB" },
  { value: "steinbach", label: "Steinbach", state: "MB" },

  { value: "fredericton", label: "Fredericton", state: "NB" },
  { value: "moncton", label: "Moncton", state: "NB" },
  { value: "saint_john", label: "Saint John", state: "NB" },

  { value: "st_johns", label: "St. John's", state: "NL" },
  { value: "mount_pearl", label: "Mount Pearl", state: "NL" },
  { value: "corner_brook", label: "Corner Brook", state: "NL" },

  { value: "halifax", label: "Halifax", state: "NS" },
  { value: "sydney", label: "Sydney", state: "NS" },
  { value: "dartmouth", label: "Dartmouth", state: "NS" },

  { value: "toronto", label: "Toronto", state: "ON" },
  { value: "ottawa", label: "Ottawa", state: "ON" },
  { value: "mississauga", label: "Mississauga", state: "ON" },
  { value: "brampton", label: "Brampton", state: "ON" },
  { value: "hamilton", label: "Hamilton", state: "ON" },

  { value: "charlottetown", label: "Charlottetown", state: "PE" },
  { value: "summerside", label: "Summerside", state: "PE" },

  { value: "montreal", label: "Montreal", state: "QC" },
  { value: "quebec_city", label: "Quebec City", state: "QC" },
  { value: "laval", label: "Laval", state: "QC" },
  { value: "gatineau", label: "Gatineau", state: "QC" },

  { value: "saskatoon", label: "Saskatoon", state: "SK" },
  { value: "regina", label: "Regina", state: "SK" },
  { value: "prince_albert", label: "Prince Albert", state: "SK" },

  { value: "yellowknife", label: "Yellowknife", state: "NT" },
  { value: "hay_river", label: "Hay River", state: "NT" },

  { value: "iqaluit", label: "Iqaluit", state: "NU" },

  { value: "whitehorse", label: "Whitehorse", state: "YT" },
  { value: "dawson_city", label: "Dawson City", state: "YT" }
];

export const relationshipStatusOptions = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
  { value: "divorced", label: "Divorced" },
  { value: "widowed", label: "Widowed" },
  { value: "separated", label: "Separated" },
  { value: "other", label: "Other" },
  { value: "prefer_not_to_say", label: "Prefer not to say" }
];

export const zipCodesOptions = [
  { label: "Toronto, ON - M5V 3L9", value: "M5V 3L9" },
  { label: "Vancouver, BC - V5K 0A1", value: "V5K 0A1" },
  { label: "Montreal, QC - H3B 4W8", value: "H3B 4W8" },
  { label: "Calgary, AB - T2P 5H1", value: "T2P 5H1" },
  { label: "Ottawa, ON - K1A 0B1", value: "K1A 0B1" },
  { label: "Edmonton, AB - T5J 0N3", value: "T5J 0N3" },
  { label: "Quebec City, QC - G1R 5P7", value: "G1R 5P7" },
  { label: "Halifax, NS - B3J 3N5", value: "B3J 3N5" },
  { label: "Winnipeg, MB - R3C 4T3", value: "R3C 4T3" }
];

export const ethnicityOptions = [
  { label: "Caucasian", value: "caucasian" },
  { label: "African American", value: "african_american" },
  { label: "Asian", value: "asian" },
  { label: "Hispanic or Latino", value: "hispanic_latino" },
  { label: "Native American", value: "native_american" },
  { label: "Pacific Islander", value: "pacific_islander" },
  { label: "Middle Eastern", value: "middle_eastern" },
  { label: "Other", value: "other" },
  { label: "Prefer not to say", value: "prefer_not_to_say" }
];

export const economicStatusOptions = [
  { label: "Low-income", value: "lowIncome" },
  { label: "Middle-class", value: "middleClass" },
  { label: "Upper middle-class", value: "upperMiddleClass" },
  { label: "High-income", value: "highIncome" },
  { label: "Prefer not to say", value: "preferNotToSay" }
];

export const homeOwnershipStatusOptions = [
  { label: "Own", value: "own" },
  { label: "Rental", value: "rental" }
];

export const politicalAffiliationsOptions = [
  { label: "Democrat", value: "democrat" },
  { label: "Republican", value: "republican" },
  { label: "Independent", value: "independent" },
  { label: "Libertarian", value: "libertarian" },
  { label: "Green Party", value: "green_party" },
  { label: "Constitution Party", value: "constitution_party" },
  { label: "Other", value: "other" },
  { label: "Prefer not to say", value: "prefer_not_to_say" }
];

export const CaseNames = [
  { value: "1", label: "Case Number 1" },
  { value: "2", label: "Case Number 2" }
];

export const STRIKE_JUROR_REASONS = [
  { name: "cause", label: "Challenge - Cause" },
  { name: "peremptory", label: "Challenge - Peremptory" },
  { name: "biasParty", label: "Bias/Prejudice - Party" },
  { name: "biasSubject", label: "Bias/Prejudice - Subject matter" },
  { name: "excused", label: "Excused" },
  { name: "other", label: "Other" }
];
