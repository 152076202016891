import { Formik } from "formik";
import React from "react";
import { CreateCaseDocumentProps, DocumentFormValues } from "../../../../utils/types";
import { ModalWrapper } from "../../../ModalWrapper";
import CreateDocumentForm from "./CreateDocumentForm";
import { initialValues, validationSchema } from "./helper";

const CreateCaseDocument = ({ showModal, setShowModal, onAddDocument }: CreateCaseDocumentProps) => {
  return (
    <ModalWrapper
      showModal={showModal}
      showHeader
      headerTitle='Add New Document'
      onClose={() => {
        setShowModal(false);
      }}
    >
      <Formik<DocumentFormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        <CreateDocumentForm
          onAddDocument={onAddDocument}
          onClose={() => {
            setShowModal(false);
          }}
        />
      </Formik>
    </ModalWrapper>
  );
};

export default CreateCaseDocument;
