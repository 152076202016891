import { ThunkDispatch } from "@reduxjs/toolkit";
import { allCases, setTotalCases } from "../../redux/slices/casesSlices";
import {
  fetchCandidates,
  fetchExperts,
  fetchWitnesses,
  setTrialTeamParticipants
} from "../../redux/slices/participantsSlice";
import { RootState } from "../../redux/store";
import { MEMBER_TYPES } from "../constants";
import { SOCKET_EVENTS } from "../constants/socketEvents";
import { AnervaServerResponseProps, AnyAction, Participant, SocketPendingRequestProps } from "../types";

export const handleEventData = (
  dispatch: ThunkDispatch<RootState, unknown, AnyAction> | null = null,
  response: AnervaServerResponseProps
) => {
  if (!response || !dispatch) return;
  const respData = response;
  const { success, eventType, data, type } = respData;
  if (
    success === true &&
    (eventType === SOCKET_EVENTS.MANAGE_CASE ||
      eventType === SOCKET_EVENTS.MANAGE_CASE_DETAILS ||
      eventType === SOCKET_EVENTS.MANAGE_COURT_DATE) &&
    dispatch
  ) {
    if (data && typeof data === "object" && "cases" in data && Array.isArray(data.cases)) {
      dispatch(allCases(data.cases));
      dispatch(setTotalCases(data?.total || 0));
    }
  }

  if (
    success === true &&
    (eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT_RATING ||
      eventType === SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT_DETAILS) &&
    (type === SOCKET_EVENTS.FETCH_PARTICIPANT_NON_TRIAL || type === SOCKET_EVENTS.PARTICIPANT_DATA_NON_TRIAL) &&
    dispatch
  ) {
    const candidatesList: Participant[] = [];
    const expertsList: Participant[] = [];
    const witnessList: Participant[] = [];
    if (data && Array.isArray(data?.participants)) {
      data?.participants?.map((item: Participant) => {
        if (
          item.caseParticipants &&
          item.caseParticipants.length > 0 &&
          item.caseParticipants[0]?.participantType === MEMBER_TYPES[0]
        ) {
          witnessList.push(item);
        }
        if (
          item.caseParticipants &&
          item.caseParticipants.length > 0 &&
          item.caseParticipants[0]?.participantType === MEMBER_TYPES[1]
        ) {
          expertsList.push(item);
        }
        if (
          item.caseParticipants &&
          item.caseParticipants.length > 0 &&
          item.caseParticipants[0]?.participantType === MEMBER_TYPES[2]
        ) {
          candidatesList.push(item);
        }
      });
    }
    dispatch(fetchCandidates(candidatesList));
    dispatch(fetchExperts(expertsList));
    dispatch(fetchWitnesses(witnessList));
  }

  if (
    success === true &&
    (eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT_DETAILS) &&
    (type === SOCKET_EVENTS.FETCH_PARTICIPANT_TRIAL || type === SOCKET_EVENTS.PARTICIPANT_DATA_TRIAL) &&
    dispatch
  ) {
    const participants = data.participants.map((participant: Participant) => ({
      firstName: participant?.firstName || "-",
      lastName: participant?.lastName || "-",
      email: participant?.email || "-",
      phoneNumber: participant?.phoneNumber || "-",
      city: participant?.city || "-",
      seatNumber: participant?.caseParticipants?.[0]?.seatNumber || "-",
      actions: "actions", // Placeholder,
      caseParticipants: participant?.caseParticipants ? [participant.caseParticipants[0]] : []
    }));
    dispatch(setTrialTeamParticipants(participants));
  }
};

export function handlePendingRequests(
  pendingRequests: SocketPendingRequestProps[],
  data: AnervaServerResponseProps
): SocketPendingRequestProps[] {
  if (pendingRequests.length === 0 || typeof data !== "object" || data === null) {
    return pendingRequests;
  }

  const responseData = data;
  if (!responseData.requestId) {
    return pendingRequests;
  }

  const matchingRequestIndex = pendingRequests.findIndex(
    (request) => request.requestId?.toString() === responseData.requestId?.toString()
  );
  if (matchingRequestIndex === -1) {
    return pendingRequests;
  }

  const { onSuccess, onFailure } = pendingRequests[matchingRequestIndex];
  if (onSuccess && responseData.success) {
    onSuccess(responseData);
  }

  if (onFailure && !responseData.success) {
    onFailure(responseData);
  }
  const requests: SocketPendingRequestProps[] = pendingRequests;
  requests.splice(matchingRequestIndex, 1);
  // Create a new array without the handled request
  return requests;
}

export function handleErrorResponse(
  pendingRequests: SocketPendingRequestProps[],
  data: AnervaServerResponseProps
): SocketPendingRequestProps[] {
  if (pendingRequests.length === 0 || typeof data !== "object" || data === null) {
    return pendingRequests;
  }

  const responseData = data;
  if (!responseData.requestId || responseData.success) {
    return pendingRequests;
  }

  const matchingRequestIndex = pendingRequests.findIndex(
    (request) => request.requestId?.toString() === responseData.requestId?.toString()
  );

  if (matchingRequestIndex === -1) {
    return pendingRequests;
  }

  const { onFailure } = pendingRequests[matchingRequestIndex];

  if (onFailure) {
    onFailure(responseData);
  }
  const requests: SocketPendingRequestProps[] = pendingRequests;
  requests.splice(matchingRequestIndex, 1);
  return requests;
}
