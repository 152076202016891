import React from "react";
import { BreadCrumbs } from "../../components";
import NavigationWrapper from "../../components/NavigationWrapper";
import AddNewPerson from "../../components/Pages/AddNewPerson";
import { titleMap, TITLES } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import "./styles.scss";

const AddNewClient: React.FC = () => {
  const pathSegment = location.pathname.split("/").pop();
  const title = titleMap[pathSegment as keyof typeof titleMap] || TITLES.ADD_NEW_CANDIDATE;

  return (
    <div className='d-flex flex-column'>
      <BreadCrumbs
        breadcrumbItems={[
          {
            text: "Manage Case",
            url: routes.MANAGE_CASE
          },
          { text: "Case Participants", url: location.pathname }
        ]}
      />
      <NavigationWrapper title={title}>
        <AddNewPerson onSubmit={() => {}} />
      </NavigationWrapper>
    </div>
  );
};

export default AddNewClient;
