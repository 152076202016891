import { Formik } from "formik";
import React, { useState } from "react";
import { manageCaseTabButtons } from "../../../../utils/constants";
import { CreateNewCaseProps, NewCaseFormValues } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";
import { WrapperComponent } from "../../../WrapperComponent";
import CourtDates from "./CourtDates";
import { caseDetailsValidationSchema, courtDatesValidationSchema, initialValues } from "./helper";
import NewCaseForm from "./NewCaseForm";
import "./styles.scss";

const CreateNewCase: React.FC<CreateNewCaseProps> = ({ handleTabChange, caseData }) => {
  const [activeTab, setActiveTab] = useState(manageCaseTabButtons[0]);

  const renderTabContent = () => {
    switch (activeTab) {
      case manageCaseTabButtons[0]:
        return (
          <WrapperComponent>
            <Formik<NewCaseFormValues>
              initialValues={initialValues}
              validationSchema={caseDetailsValidationSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <NewCaseForm handleTabChange={handleTabChange} caseData={caseData} />
            </Formik>
          </WrapperComponent>
        );
      case manageCaseTabButtons[1]:
        return (
          <WrapperComponent>
            <Formik<NewCaseFormValues>
              initialValues={initialValues}
              validationSchema={courtDatesValidationSchema}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              <CourtDates handleTabChange={handleTabChange} caseData={caseData} />
            </Formik>
          </WrapperComponent>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TabButtons tabs={manageCaseTabButtons} activeTab={activeTab} onTabChange={(tab) => setActiveTab(tab)} />
      {renderTabContent()}
    </>
  );
};

export default CreateNewCase;
