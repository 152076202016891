import { Form, useFormikContext } from "formik";
import { useCallback, useState } from "react";
import { BUTTON_TITLE, FORM_LABELS, FORM_NAMES, navCards, personalDataPageTabs } from "../../../../../utils/constants";
import { CommunicationPreferencesFormValueProps, LanguageField, PersonalDataProps } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import { AddFieldValueModal } from "../../../../AddFieldValueModal";
import AddMore from "../../../../AddMoreButton";
import SelectComponent from "../../../../SelectComponent";
import ToggleButton from "../../../../ToggleButton/toggleButton";

const CommunicationPreferencesForm: React.FC<PersonalDataProps> = ({ handleTabChange, setActiveCard }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [languagesList, setLanguagesList] = useState<LanguageField[]>([
    {
      value: "english",
      label: "English",
      name: "english"
    }
  ]);

  const { values, errors, handleSubmit, setFieldValue, resetForm } =
    useFormikContext<CommunicationPreferencesFormValueProps>();

  const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), []);

  const handleAddSocialMedia = useCallback(
    (label: string) => {
      const newField: LanguageField = {
        value: `social_field_${languagesList.length}`,
        label
      };
      setLanguagesList((prev) => [...prev, newField]);
      toggleModal();
    },
    [languagesList.length, toggleModal]
  );
  return (
    <Form onSubmit={handleSubmit}>
      <div>
        <div className='d-flex flex-row gap-4 sm-flex-wrap align-items-center mb-4 mt-1'>
          <div className='w-100'>
            <SelectComponent
              label={FORM_LABELS.PREFERRED_LANGUAGE}
              name={FORM_NAMES.PREFERRED_LANGUAGE}
              errorMessage={errors?.preferredLanguage}
              items={languagesList}
              value={values?.preferredLanguage}
              onChange={(e) => setFieldValue(FORM_NAMES.PREFERRED_LANGUAGE, e.target.value)}
            />
          </div>
          <div className='w-100'>
            <AddMore
              btnClassName='mt-3'
              title={BUTTON_TITLE.ADD_ONE_MORE_LANGUAGE}
              onClick={() => {
                toggleModal();
              }}
            />
          </div>
        </div>
      </div>
      <p className='my-2 color-primary-light font-size-15'>PREFERRED COMMUNICATION CHANNELS</p>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.EMAIL}
            isChecked={values.email}
            handleChange={(checked) => setFieldValue(FORM_NAMES.EMAIL, checked)}
          />
        </div>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.PHONE_CALLS}
            isChecked={values.phoneCalls}
            handleChange={(checked) => setFieldValue(FORM_NAMES.PHONE_CALLS, checked)}
          />
        </div>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.FACE_TO_FACE_MEETINGS}
            isChecked={values.faceToFaceMeetings}
            handleChange={(checked) => setFieldValue(FORM_NAMES.FACE_TO_FACE_MEETINGS, checked)}
          />
        </div>
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.VIDEO_CONFERENCING}
            isChecked={values.videoConferencing}
            handleChange={(checked) => setFieldValue(FORM_NAMES.VIDEO_CONFERENCING, checked)}
          />
        </div>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.MICROSOFT_TEAMS}
            isChecked={values.microsoftTeams}
            handleChange={(checked) => setFieldValue(FORM_NAMES.MICROSOFT_TEAMS, checked)}
          />
        </div>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.GOOGLE_HANGOUTS}
            isChecked={values.googleHangouts}
            handleChange={(checked) => setFieldValue(FORM_NAMES.GOOGLE_HANGOUTS, checked)}
          />
        </div>
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.WHATSAPP}
            isChecked={values.whatsapp}
            handleChange={(checked) => setFieldValue(FORM_NAMES.WHATSAPP, checked)}
          />
        </div>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.TELEGRAM}
            isChecked={values.telegram}
            handleChange={(checked) => setFieldValue(FORM_NAMES.TELEGRAM, checked)}
          />
        </div>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.SKYPE}
            isChecked={values.skype}
            handleChange={(checked) => setFieldValue(FORM_NAMES.SKYPE, checked)}
          />
        </div>
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          {" "}
          <ToggleButton
            label={FORM_LABELS.FACEBOOK_MESSENGER}
            isChecked={values.facebookMessenger}
            handleChange={(checked) => setFieldValue(FORM_NAMES.FACEBOOK_MESSENGER, checked)}
          />
        </div>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.APPLE_I_MESSAGE}
            isChecked={values.appleiMessage}
            handleChange={(checked) => setFieldValue(FORM_NAMES.APPLE_I_MESSAGE, checked)}
          />
        </div>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.INSTAGRAM_DIRECT}
            isChecked={values.instagramDirect}
            handleChange={(checked) => setFieldValue(FORM_NAMES.INSTAGRAM_DIRECT, checked)}
          />
        </div>
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <ToggleButton
            label={FORM_LABELS.SIGNAL}
            isChecked={values.signal}
            handleChange={(checked) => setFieldValue(FORM_NAMES.SIGNAL, checked)}
          />
        </div>
        <div className='w-100'>
          {" "}
          <ToggleButton
            label={FORM_LABELS.SNAPCHAT}
            isChecked={values.snapchat}
            handleChange={(checked) => setFieldValue(FORM_NAMES.SNAPCHAT, checked)}
          />
        </div>
        <div className='w-100'></div>
      </div>
      <div className='d-flex gap-2 mt-3'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          onClick={() => {
            setActiveCard?.(navCards[1]);
            resetForm();
            if (handleTabChange)
              handleTabChange({
                value: "communicationPreferences",
                label: "Communication Preferences",
                id: 8
              });
          }}
          className='bg-primary-light'
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[6]);
            resetForm();
          }}
          className='bg-red'
        />
      </div>
      <AddFieldValueModal
        fieldLabel='Add Language'
        fieldPlaceholder='Type new Language'
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSubmit={handleAddSocialMedia}
      />
    </Form>
  );
};

export default CommunicationPreferencesForm;
