import React, { useCallback, useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { Modal, ModalBody } from "reactstrap";
import { BUTTON_TITLE, FORM_PLACEHOLDERS, STRIKING_REASONS, STRINGS } from "../../utils/constants";
import useSocket from "../../utils/hooks/sockets";
import {
  InputChangeEvent,
  SocketEmitFunction,
  StrikeJurorModalProps,
  StrikeReason,
  StrikingReason
} from "../../utils/types";
import ActionButton from "../ActionButton";
import TextInput from "../TextInput";
import { getDefaultValues, handleCheckboxChange, handleSubmit } from "./helper";
import "./strikeJuror.scss";

const StrikeJurorModal: React.FC<StrikeJurorModalProps> = ({
  isOpen = false,
  onClose = () => {},
  currentParticipant = null
}) => {
  const { socketEmit } = useSocket() as { socketEmit: SocketEmitFunction };
  const [selectedReasons, setSelectedReasons] = useState(STRIKING_REASONS);
  const [rating, setRating] = useState<number>(1);
  const [isRatingDisabled, setIsRatingDisabled] = useState<boolean>(false);
  const [otherReason, setOtherReason] = useState<string>("");

  useEffect(() => {
    const { selectedReasons, rating, otherReason, isRatingDisabled } = getDefaultValues(currentParticipant);
    setSelectedReasons(selectedReasons);
    setRating(rating);
    setOtherReason(otherReason);
    setIsRatingDisabled(isRatingDisabled);
  }, [currentParticipant]);

  const handleOtherReasonChange = useCallback((event: InputChangeEvent) => {
    setOtherReason(event.target.value);
  }, []);

  const renderOtherReasonInput = (
    selectedReasons: StrikingReason[],
    otherReason: string,
    handleOtherReasonChange: (event: InputChangeEvent) => void
  ) => {
    return (
      selectedReasons[5]?.value && (
        <TextInput placeholder={FORM_PLACEHOLDERS.REASON_HERE} value={otherReason} onChange={handleOtherReasonChange} />
      )
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        onClose();
        setOtherReason("");
      }}
      className='strike-juror-modal'
    >
      <ModalBody>
        <div className='strike-juror-modal-content'>
          <p className='strike-juror-modal-title'>{STRINGS.STRIKE}</p>
          <p className='juror-subtitle'>Reason for striking?</p>

          <div className='strike-juror-checkbox-group'>
            {selectedReasons.map(({ type, value, text }: StrikeReason) => (
              <div
                key={type}
                className={`custom-checkbox ${value ? "checked" : ""}`}
                onClick={() =>
                  handleCheckboxChange(
                    type,
                    !value,
                    selectedReasons,
                    setSelectedReasons,
                    setIsRatingDisabled,
                    setRating
                  )
                }
              >
                <span className='color-red'>{text}</span>
                <div className={`checkbox-indicator ${value ? "checked" : ""}`} />
              </div>
            ))}

            {renderOtherReasonInput(selectedReasons, otherReason, handleOtherReasonChange)}

            <div className='mb-4 mt-2'>
              <p className='strike-juror-modal-title color-primary-light'>{STRINGS.RATING}</p>
              <Rating
                onClick={(newRating) => setRating(newRating)}
                initialValue={rating}
                size={30}
                fillColor={isRatingDisabled ? "#b61111" : "#137c4e"}
                emptyColor='#dce0d9'
                allowHover={true}
                readonly={isRatingDisabled}
                iconsCount={5}
                transition
              />
            </div>
          </div>

          <div className='strike-juror-modal-buttons'>
            <ActionButton
              className='save-button padding-x'
              onClick={() =>
                handleSubmit(socketEmit, currentParticipant, rating, selectedReasons, otherReason, onClose)
              }
              title={BUTTON_TITLE.APPLY}
            />
            <ActionButton className='cancel-button padding-x' onClick={onClose} title={BUTTON_TITLE.CANCEL} />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StrikeJurorModal;
