import { ConfigureTabProps, InputField } from "../types";
import { FORM_LABELS, FORM_NAMES, FORM_PLACEHOLDERS } from "./labels";

export const inputFieldsArray: InputField[] = [
  {
    id: 9,
    label: FORM_LABELS.NICKNAME,
    isMandatory: false,
    name: FORM_NAMES.NICKNAME,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 10,
    label: FORM_LABELS.AGE,
    isMandatory: false,
    name: FORM_NAMES.AGE,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "number",
    min: 10,
    max: 120,
    value: ""
  },
  {
    id: 11,
    label: FORM_LABELS.OCCUPATION,
    isMandatory: false,
    name: FORM_NAMES.OCCUPATION,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 12,
    label: FORM_LABELS.ADDRESS,
    isMandatory: false,
    name: FORM_NAMES.ADDRESS,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 13,
    label: FORM_LABELS.ADDITIONAL_ADDRESS,
    isMandatory: false,
    name: FORM_NAMES.ADDITIONAL_ADDRESS,
    placeholder: FORM_PLACEHOLDERS.ADDITIONAL_ADDRESS,
    type: "text",
    value: ""
  },
  {
    id: 14,
    label: FORM_LABELS.CITY,
    isMandatory: false,
    name: FORM_NAMES.CITY,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 15,
    label: FORM_LABELS.STATE,
    isMandatory: false,
    name: FORM_NAMES.STATE,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 16,
    label: FORM_LABELS.COUNTRY,
    isMandatory: false,
    name: FORM_NAMES.COUNTRY,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 17,
    label: FORM_LABELS.ZIP_CODE,
    isMandatory: false,
    name: FORM_NAMES.ZIP_CODE,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 18,
    label: FORM_LABELS.CHILDREN,
    isMandatory: false,
    name: FORM_NAMES.CHILDREN_TEXT,
    type: "number",
    isSwitch: true,
    value: ""
  },
  {
    id: 19,
    label: FORM_LABELS.CHILD_SUPPORT,
    isMandatory: false,
    name: FORM_NAMES.CHILD_SUPPORT_TEXT,
    type: "text",
    isSwitch: true,
    value: ""
  },
  {
    id: 20,
    label: FORM_LABELS.RELATIONSHIP_STATUS,
    isMandatory: false,
    name: FORM_NAMES.RELATIONSHIP_STATUS,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 21,
    label: FORM_LABELS.ADDITIONAL_INFORMATION_OPTIONAL,
    isMandatory: false,
    name: FORM_NAMES.ADDITIONAL_INFORMATION_OPTIONAL,
    type: "textarea",
    value: ""
  },
  {
    id: 22,
    label: FORM_LABELS.ETHNICITY,
    isMandatory: false,
    name: FORM_NAMES.ETHNICITY,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 23,
    label: FORM_LABELS.WORKPLACE,
    isMandatory: false,
    name: FORM_NAMES.WORKPLACE,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 24,
    label: FORM_LABELS.ACADEMIC_RECORDS,
    isMandatory: false,
    name: FORM_NAMES.ACADEMIC_RECORDS,
    placeholder: FORM_PLACEHOLDERS.DEFAULT,
    type: "text",
    value: ""
  },
  {
    id: 25,
    label: FORM_LABELS.ESTIMATED_HOUSEHOLD_INCOME,
    isMandatory: false,
    name: FORM_NAMES.ESTIMATED_HOUSEHOLD_INCOME,
    type: "currency",
    currencyText: "per year",
    value: ""
  },
  {
    id: 26,
    label: FORM_LABELS.ESTIMATED_NET_WORTH,
    isMandatory: false,
    name: FORM_NAMES.ESTIMATED_NET_WORTH,
    type: "currency",
    currencyText: "M",
    value: ""
  },
  {
    id: 27,
    label: FORM_LABELS.LENGTH_OF_RESIDENCE,
    isMandatory: false,
    name: FORM_NAMES.LENGTH_OF_RESIDENCE,
    type: "text",
    value: ""
  }
];

export const caseFieldsArray: InputField[] = [
  {
    label: "Client Name",
    isMandatory: false,
    name: "clientName",
    placeholder: "Type here",
    type: "text",
    value: null
  },
  {
    label: "Case Status",
    isMandatory: false,
    name: "caseStatus",
    placeholder: "Select",
    type: "select",
    value: null
  },
  {
    label: "Client Jurisdiction",
    isMandatory: false,
    name: "clientJurisdiction",
    placeholder: "Type here",
    type: "text",
    value: null
  },
  {
    label: "Venue Type",
    isMandatory: false,
    name: "venueType",
    placeholder: "Select",
    type: "select",
    value: null
  },
  {
    label: "Conflict Check",
    isMandatory: false,
    name: "conflictCheck",
    type: "switch",
    value: null,
    isSwitch: true
  },
  {
    label: "Trial Start Date",
    isMandatory: false,
    name: "trialStartDate",
    type: "date",
    value: null
  },
  {
    label: "Filing Date",
    isMandatory: false,
    name: "filingDate",
    type: "date",
    value: null
  },
  {
    label: "Hearing Date",
    isMandatory: false,
    name: "hearingDate",
    type: "date",
    value: null
  },
  {
    label: "Confirmation",
    isMandatory: false,
    name: "confirmation",
    type: "text",
    value: null
  },
  {
    label: "Date Of Incident/Accident",
    isMandatory: false,
    name: "dateOfIncidentAccident",
    type: "date",
    value: null
  },
  {
    label: "Defendants Name",
    isMandatory: false,
    name: "defendantsName",
    type: "text",
    value: null
  },
  {
    label: "Opposing Counsel's Name",
    isMandatory: false,
    name: "opposingCounselsName",
    type: "text",
    value: null
  },
  {
    label: "Existing Case Staff/Team",
    isMandatory: false,
    name: "existingCaseStaffTeam",
    type: "text",
    value: null
  },
  {
    label: "Annual Value",
    isMandatory: false,
    name: "annualValue",
    type: "currency",
    value: null
  },
  {
    label: "Recovery Value",
    isMandatory: false,
    name: "recoveryValue",
    type: "currency",
    value: null
  },
  {
    label: "Additional Information (Optional)",
    isMandatory: false,
    name: "additionalInformation",
    type: "textarea",
    value: null
  }
];

export const PariticpantInitialTabs: ConfigureTabProps[] = [
  {
    id: 1,
    label: "Basic",
    value: "basic",

    content: [
      {
        id: 1,
        label: FORM_LABELS.FIRST_NAME,
        isMandatory: false,
        name: FORM_NAMES.FIRST_NAME,
        placeholder: FORM_PLACEHOLDERS.DEFAULT,
        type: "text",
        isFixed: true,
        value: ""
      },
      {
        id: 2,
        label: FORM_LABELS.LAST_NAME,
        isMandatory: false,
        name: FORM_NAMES.LAST_NAME,
        placeholder: FORM_PLACEHOLDERS.DEFAULT,
        type: "text",
        isFixed: true,
        value: ""
      },
      {
        id: 3,
        label: FORM_LABELS.EMAIL,
        isMandatory: false,
        name: FORM_NAMES.EMAIL,
        placeholder: FORM_PLACEHOLDERS.DEFAULT,
        type: "email",
        isFixed: true,
        value: ""
      },
      {
        id: 7,
        label: FORM_LABELS.PHONE_NUMBER,
        isMandatory: false,
        name: FORM_NAMES.PHONE_NUMBER,
        placeholder: FORM_PLACEHOLDERS.DEFAULT,
        type: "text",
        isFixed: true,
        value: ""
      }
    ]
  }
];
export const CaseInitialTabs: ConfigureTabProps[] = [
  {
    id: 1,
    label: "Basic",
    value: "basic",
    content: [
      {
        id: 1,
        label: FORM_LABELS.CASE_NAME,
        isMandatory: true,
        name: FORM_NAMES.CASE_NAME,
        placeholder: FORM_PLACEHOLDERS.DEFAULT,
        type: "text",
        isFixed: true,
        value: ""
      },
      {
        id: 2,
        label: FORM_LABELS.COURT_CASE_NUMBER,
        isMandatory: true,
        name: FORM_NAMES.COURT_CASE_NUMBER,
        placeholder: FORM_PLACEHOLDERS.DEFAULT,
        type: "text",
        isFixed: true,
        value: ""
      }
    ]
  }
];
