/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import React from "react";
import { Sidebar_Props } from "./layout_types";
import SidebarContent from "./SidebarContent";

const Sidebar = ({
  menuItems,
  logoImage,
  logoSmImage,
  leftSideBarType,
  hasDropdownMenu,
  dropdownChangeHandler,
  dropdownPlaceholder,
  dropdownValue,
  dropdownOptions,
  showWorldClock = true,
  versionNumber,
  env
}: Sidebar_Props) => {
  return (
    <React.Fragment>
      <div className={`vertical-menu ${leftSideBarType === "menu-closed" ? "menu-closed" : ""}`}>
        <div data-simplebar style={{ height: showWorldClock ? "calc(100vh - 30px)" : "100%" }}>
          <SidebarContent
            menuItems={menuItems}
            logoImage={logoImage}
            logoSmImage={logoSmImage}
            hasDropdownMenu={hasDropdownMenu}
            dropdownValue={dropdownValue}
            dropdownChangeHandler={dropdownChangeHandler}
            dropdownPlaceholder={dropdownPlaceholder}
            dropdownOptions={dropdownOptions}
            showWorldClock={showWorldClock}
            versionNumber={versionNumber}
            env={env}
            leftSideBarType={leftSideBarType}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
