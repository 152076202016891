import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { getUniqueId } from "../../utils/helpers";
import { MenuItem, SidebarMenuProps } from "./layout_types";
import SidebarMenuLink from "./SidebarMenuLink";

const SidebarMenu = ({ menu, index, collapse, onCollapse }: SidebarMenuProps) => {
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const isActive = useMemo(
    () => !!menu.children?.find((item: MenuItem) => location.pathname === item.route),
    [location.pathname, menu.children]
  );

  useEffect(() => {
    setIsCollapsed(collapse);
  }, [collapse]);

  const onToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    onCollapse(isCollapsed ? menu : undefined);
  };

  return (
    <React.Fragment>
      <li className={!isCollapsed ? "mm-active" : isActive ? "active" : ""}>
        {menu["tooltip_en"] && (
          <UncontrolledTooltip placement='top' target={`tooltip-${index}`}>
            {menu["tooltip_en"]}
          </UncontrolledTooltip>
        )}
        <Link
          to='#'
          className={`has-arrow waves-effect ${isActive ? "mm-active" : ""}`}
          id={`tooltip-${index}`}
          onClick={onToggleCollapse}
        >
          <i className={menu.icon!} />
          <span className='font-size-15'>{menu["title_en"]}</span>
        </Link>
        <ul className={`sub-menu mm-collapse ${isCollapsed ? "" : "mm-show"}`} aria-expanded='false'>
          {menu.children?.map((menuLink, i) => (
            <SidebarMenuLink
              key={`key_${getUniqueId()}`}
              link={menuLink}
              label={menuLink["subtitle_en"] || ""}
              index={`${index}-${i}`}
            />
          ))}
          <hr className='sub-menu-bottom-border' />
        </ul>
      </li>
    </React.Fragment>
  );
};

export default SidebarMenu;
