import * as Yup from "yup";
import { DocumentFormValues } from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  caseName: Yup.string().optional(),
  documentName: Yup.string().optional()
});

export const initialValues: DocumentFormValues = {
  documentName: "",
  caseName: "",
  files: null
};
