import { Form, Formik } from "formik";
import { useDrop } from "react-dnd";
import { BUTTON_TITLE } from "../../utils/constants";
import { ConfigureTabProps, CustomizableTabSectionProps, InputField } from "../../utils/types";
import ActionButton from "../ActionButton";
import DraggableInputField from "../FormConfigurator/components/DraggableInputField";
import SortIcons from "../SortIcons";
import WrapperComponent from "../WrapperComponent";

const CustomizableTabSection = ({
  tab,
  handleFieldChange,
  isConfigureMode,
  onMoveDown,
  onMoveUp,
  handleRemoveField,
  formConfigurator,
  moveField,
  initialValues,
  validationSchema,
  onFormSubmit
}: CustomizableTabSectionProps) => {
  const [, drop] = useDrop({
    accept: "Field",
    drop: (item: InputField) => {
      handleFieldDrop(item);
    },
    canDrop: () => isConfigureMode || true
  });

  const handleFieldDrop = (field: InputField) => {
    if (Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name)) {
      console.log(
        "Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name) : ",
        Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name)
      );
    } else {
      const updatedTab: ConfigureTabProps = {
        ...tab,
        content: Array.isArray(tab.content) ? [...tab.content, field] : [field]
      };
      handleFieldChange(updatedTab);
    }
  };
  const handleSubmit = () => {};

  return (
    <div className='h-100 w-100 d-flex user-select-none'>
      <WrapperComponent key={tab.value} title={tab.label}>
        <div className='w-100 d-flex flex-row'>
          <div ref={drop} className='w-100'>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
              <Form>
                {tab.content &&
                  Array.isArray(tab.content) &&
                  tab.content.length > 0 &&
                  tab.content.map((field: InputField, index: number) => {
                    const isFirstIndex = index === 0 || tab.content[index - 1]?.isFixed || false;
                    const isLastIndex = index === tab.content.length - 1;

                    return (
                      <div
                        key={`${field.id}-${index}-${tab.content.length}`}
                        className='w-100 d-flex flex-row align-items-center gap-3'
                      >
                        <DraggableInputField
                          field={field}
                          currentIndex={index}
                          moveField={moveField}
                          isInputField={true}
                          isConfigureMode={isConfigureMode}
                          onChange={() => {}}
                          onSwitchChange={field.isSwitch ? () => {} : undefined}
                        />
                        {isConfigureMode && !field.isFixed && (
                          <div className='d-flex flex-row align-items-center pt-2 gap-2'>
                            <span
                              className='cursor-pointer'
                              onClick={() => {
                                handleRemoveField(field);
                              }}
                            >
                              <i className='fa-regular fa-trash-can h-px-17 w-px-17 color-red '></i>
                            </span>
                            <SortIcons
                              onMoveDown={() => {
                                if (onMoveDown) onMoveDown(field);
                              }}
                              onMoveUp={() => {
                                if (onMoveUp && !tab.content[index - 1]?.isFixed) onMoveUp(field);
                              }}
                              isFirstIndex={isFirstIndex}
                              isLastIndex={isLastIndex}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                {tab.content && Array.isArray(tab.content) && tab.content.length > 0 && (
                  <div className='d-flex flex-row gap-4 sm-flex-wrap'>
                    <ActionButton
                      title={BUTTON_TITLE.SAVE}
                      className='save-button'
                      onClick={() => {
                        onFormSubmit(tab);
                      }}
                    />
                  </div>
                )}
              </Form>
            </Formik>
          </div>
          {formConfigurator}
        </div>
      </WrapperComponent>
    </div>
  );
};

export default CustomizableTabSection;
