import * as Yup from "yup";

export const initialValues = {
  evidenceDescription: "",
  docType: "",
  courtCaseNumber: "",
  clientName: "",
  dateAdded: "",
  addedBy: "",
  role: ""
};

export const validationSchema = Yup.object().shape({
  evidenceDescription: Yup.string().optional(),
  docType: Yup.string().optional(),
  courtCaseNumber: Yup.string().optional(),
  clientName: Yup.string().optional(),
  dateAdded: Yup.string().optional(),
  addedBy: Yup.string().optional(),
  role: Yup.string().optional()
});
