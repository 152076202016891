import React, { useState, useCallback } from "react";
import "./jurorEvaluation.scss";
import { jurorEvaluationTabsData } from "../../../../utils/constants";
import { JurorEvaluationProps, Tab } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";
import BasicIndicators from "./BasicIndicators";
import BodyLanguage from "./BodyLanguage";
import CommunicationSkills from "./CommunicationSkills";
import EmotionalIntelligence from "./EmotionalIntelligence";

const JurorEvaluation: React.FC<JurorEvaluationProps> = ({ setActiveCard }) => {
  const [activeTab, setActiveTab] = useState<Tab>(jurorEvaluationTabsData[0]);

  const handleTabChange = useCallback((tab: Tab) => {
    setActiveTab(tab);
  }, []);

  const renderActiveTab = () => {
    switch (activeTab.value) {
      case jurorEvaluationTabsData[0].value:
        return <BasicIndicators setActiveCard={setActiveCard} handleTabChange={handleTabChange} />;
      case jurorEvaluationTabsData[1].value:
        return <BodyLanguage handleTabChange={handleTabChange} />;
      case jurorEvaluationTabsData[2].value:
        return <EmotionalIntelligence handleTabChange={handleTabChange} />;
      case jurorEvaluationTabsData[3].value:
        return <CommunicationSkills setActiveCard={setActiveCard} handleTabChange={handleTabChange} />;
      default:
        return null;
    }
  };

  return (
    <div className='juror-evaluation-container'>
      <TabButtons onTabChange={handleTabChange} tabs={jurorEvaluationTabsData} activeTab={activeTab} />
      {renderActiveTab()}
    </div>
  );
};

export default JurorEvaluation;
