// src/components/CustomizableTabs.tsx

import React, { useCallback, useState } from "react";
import { CustomizableTabsProps, Tab } from "../../utils/types";
import ActionButton from "../ActionButton";
import { AddFieldValueModal } from "../AddFieldValueModal";
import CustomizableTabSection from "../CustomizableTabSection";
import FormConfigurator from "../FormConfigurator";
import TabButtons from "../TabButtons";

const CustomizableTabs: React.FC<CustomizableTabsProps> = ({
  tabs,
  activeTab,
  isConfigureMode,
  inputFieldsData,
  onTabChange,
  addNewTab,
  handleFieldChange,
  onMoveDown,
  onMoveUp,
  handleRemoveField,
  moveField,
  initialValues,
  validationSchema,
  onFormSubmit
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, []);

  const handleAddNewTab = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleNewTabLabel = useCallback(
    (label: string) => {
      addNewTab(label);
      toggleModal();
    },
    [addNewTab, toggleModal]
  );

  return (
    <div>
      <div className='d-flex flex-row align-items-center justify-content-between'>
        <TabButtons tabs={tabs as Tab[]} activeTab={activeTab as Tab} onTabChange={onTabChange} />
        {isConfigureMode && <ActionButton onClick={handleAddNewTab} title='+ Add Section' />}
      </div>
      <div className='d-flex flex-row'>
        {tabs.map(
          (tab) =>
            activeTab?.value === tab.value && (
              <CustomizableTabSection
                key={tab.label}
                isConfigureMode={isConfigureMode}
                handleFieldChange={handleFieldChange}
                tab={tab}
                onMoveDown={onMoveDown}
                onMoveUp={onMoveUp}
                moveField={moveField}
                handleRemoveField={handleRemoveField}
                onFormSubmit={onFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                formConfigurator={
                  isConfigureMode && <FormConfigurator moveField={moveField} inputFields={inputFieldsData} />
                }
              />
            )
        )}
      </div>
      <AddFieldValueModal
        fieldLabel='Add Section'
        fieldPlaceholder='Section Name'
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSubmit={handleNewTabLabel}
      />
    </div>
  );
};

export default CustomizableTabs;
