import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import routes from "../../utils/constants/routes";
import { MenuActionTypes } from "../types";

const initialState: MenuActionTypes = {
  activeMenu: routes.DASHBOARD
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setActiveMenu: (state, action: PayloadAction<string | undefined>) => {
      state.activeMenu = action.payload;
    }
  }
});

export const { setActiveMenu } = menuSlice.actions;

const menuPersistConfig = {
  key: "menu",
  storage,
  whitelist: ["activeMenu"] // only selectedParticipant will be persisted
};
const persistedMenuReducer = persistReducer(menuPersistConfig, menuSlice.reducer);

export default persistedMenuReducer;
