import { Formik } from "formik";
import React from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  BUTTON_TITLE,
  FIELD_TYPES,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  STRINGS,
  USER_ID
} from "../../../utils/constants";
import { SOCKET_EVENTS } from "../../../utils/constants/socketEvents";
import { formatPhoneNumber } from "../../../utils/helpers";
import useSocket from "../../../utils/hooks/sockets";
import { AddNewFormProps, CaseListData, HanndleAddPersonProps } from "../../../utils/types";
import ActionButton from "../../ActionButton";
import TextInput from "../../TextInput";
import WrapperComponent from "../../WrapperComponent";
import { initialValues, validationSchema } from "./helper";

const AddNewPerson: React.FC<AddNewFormProps> = ({ isJuror = false, onSubmit }) => {
  const { socketEmit } = useSocket();
  const location = useLocation();
  const caseData = location.state.caseData as CaseListData | undefined;
  const currentMemberType = location.state.memberType as string | undefined;

  const handleAddPerson = (
    values: HanndleAddPersonProps,
    participantType: string | undefined,
    caseId: string | undefined,
    userId: string | undefined
  ) => {
    const payload = {
      type: SOCKET_EVENTS.ADD_PARTICIPANT,
      requestId: uuidv4(),
      eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT,
      firstName: values?.firstName || "",
      lastName: values?.lastName || "",
      email: values?.email || "",
      phoneNumber: values?.phoneNumber || "",
      participantType: participantType || "",
      city: values?.city || "",
      state: values?.state || "",
      country: values?.country || "",
      zipCode: values?.zipCode || "",
      caseId: caseId || "",
      userId: userId
    };
    try {
      if (socketEmit)
        socketEmit(
          SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
          payload,
          ({ success, message }) => {
            if (success) toast.success(message);
            else if (!success) toast.error(message);
          },
          ({ success, message }) => {
            if (!success) toast.error(message);
          }
        );
    } catch (error) {
      if (error instanceof Error) console.log("addMember error: ", error?.message);
    }
  };

  return (
    <WrapperComponent>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleAddPerson(values, currentMemberType, caseData?.id, USER_ID);
          resetForm();
          onSubmit?.(values);
          resetForm();
        }}
      >
        {({ values, errors, handleChange, resetForm, isValid, handleSubmit }) => (
          <>
            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <TextInput
                label={FORM_LABELS.FIRST_NAME}
                name={FORM_NAMES.FIRST_NAME}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.firstName}
                onChange={handleChange}
                errorMessage={errors.firstName}
              />
              <TextInput
                label={FORM_LABELS.LAST_NAME}
                name={FORM_NAMES.LAST_NAME}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.lastName}
                onChange={handleChange}
                errorMessage={errors.lastName}
              />
            </div>
            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <TextInput
                label={FORM_LABELS.EMAIL}
                name={FORM_NAMES.EMAIL}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.email}
                onChange={handleChange}
                errorMessage={errors.email}
              />
              <TextInput
                label={FORM_LABELS.PHONE_NUMBER}
                name={FORM_NAMES.PHONE_NUMBER}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.phoneNumber}
                // onChange={handleChange}
                onChange={(e) => {
                  const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                  handleChange({ target: { name: FORM_NAMES.PHONE_NUMBER, value: formattedPhoneNumber } });
                }}
                errorMessage={errors.phoneNumber}
              />
            </div>
            <div className='d-flex flex-row gap-4 sm-flex-wrap'>
              <TextInput
                label={FORM_LABELS.CITY}
                name={FORM_NAMES.CITY}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.city}
                onChange={handleChange}
                errorMessage={errors.city}
              />
              <TextInput
                label={FORM_LABELS.STATE}
                name={FORM_NAMES.STATE}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.state}
                onChange={handleChange}
                errorMessage={errors.state}
              />
            </div>
            <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
              <TextInput
                label={FORM_LABELS.COUNTRY}
                name={FORM_NAMES.COUNTRY}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.country}
                onChange={handleChange}
                errorMessage={errors.country}
              />
              <TextInput
                label={FORM_LABELS.ZIP_CODE}
                name={FORM_NAMES.ZIP_CODE}
                placeholder={FORM_PLACEHOLDERS.DEFAULT}
                value={values.zipCode}
                onChange={handleChange}
                errorMessage={errors.zipCode}
              />
            </div>
            {isJuror && (
              <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2 '>
                <TextInput
                  label={FORM_LABELS.SEAT_NO}
                  name={FORM_NAMES.SEAT_NO}
                  placeholder={FORM_PLACEHOLDERS.DEFAULT}
                  value={values.seatNumber}
                  type={FIELD_TYPES.NUMBER}
                  onChange={handleChange}
                  errorMessage={errors.seatNumber}
                  parentClassName='w-48'
                />
              </div>
            )}
            <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
              <ActionButton
                title={BUTTON_TITLE.SAVE}
                className='save-button'
                onClick={() => {
                  if (!isValid) {
                    toast.error(STRINGS.FILL_REQUIRED_FIELDS);
                    return;
                  }
                  handleSubmit();
                }}
              />
              <ActionButton
                title={BUTTON_TITLE.CANCEL}
                onClick={() => {
                  resetForm?.();
                }}
                className='cancel-button'
              />
            </div>
          </>
        )}
      </Formik>
    </WrapperComponent>
  );
};

export default AddNewPerson;
