import React, { useState, useRef, useCallback } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./styles.CustomAddHeader.scss";
import { fields } from "../../../../../utils/constants";

interface CustomAddColumnProps {
  onToggleField: (field: string) => void; // Callback to toggle field
  activeFields: { [key: string]: boolean }; // Current state of active fields
}

const CustomAddColumn: React.FC<CustomAddColumnProps> = ({ onToggleField, activeFields = {} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState<{ top: number; left: number } | null>(null);

  const iconRef = useRef<HTMLDivElement>(null);

  const toggleModal = useCallback(() => {
    if (iconRef.current) {
      const rect = iconRef.current.getBoundingClientRect();
      setModalPosition({ top: rect.bottom + window.scrollY + 10, left: rect.left - 250 + window.scrollX });
    }
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  // Define the list of fields dynamically

  return (
    <div>
      <div ref={iconRef} onClick={toggleModal} className='custom-header-component'>
        <i className='fa-regular fa-plus icon-style'></i>
      </div>

      {modalPosition && (
        <Modal
          isOpen={modalOpen}
          toggle={toggleModal}
          className='custom-modal'
          style={{ position: "absolute", top: `${modalPosition.top - 40}px`, left: `${modalPosition.left}px` }}
        >
          <div className='modal-pointer'></div>
          <ModalBody className='custom-modal-body'>
            <div>
              {fields.map((field) => (
                <div className='field-item' key={field}>
                  <p className={`field-label ${activeFields[field] ? "highlighted" : ""}`}>{field}</p>
                  <div
                    className={`custom-toggle-btn ${activeFields[field] ? "active" : ""}`}
                    onClick={() => onToggleField(field)}
                  />
                </div>
              ))}
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default CustomAddColumn;
