// useSocket.ts
import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import socketService from "../services/socketService";
import { UseSocketHook, EventData, SuccessCallback, ErrorCallback, AnervaServerResponseProps } from "../types";

const useSocket = (): UseSocketHook => {
  const [isConnected, setIsConnected] = useState<boolean>(socketService.getIsConnected());
  const dispatch = useDispatch();
  useEffect(() => {
    const unsubscribe = socketService.subscribe(setIsConnected);
    return unsubscribe;
  }, []);

  const on = useCallback(
    <T extends EventData = EventData>(eventName: string, onSuccess: SuccessCallback<T>, onFailure?: ErrorCallback) => {
      socketService.on<T>(eventName, onSuccess, onFailure);
    },
    []
  );

  const emit = <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => {
    socketService.emit<T>(eventName, data, onSuccess, onFailure);
  };

  const off = useCallback((eventName: string, onRemoveListener?: () => void) => {
    socketService.off(eventName, onRemoveListener);
  }, []);

  return {
    socket: socketService.getSocket(dispatch),
    isSocketConnected: isConnected,
    socketOn: on,
    socketEmit: emit,
    socketOff: off
  };
};

export default useSocket;
