import { Form, useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RootState } from "../../../../redux/store";
import {
  BUTTON_TITLE,
  CaseStatuses,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  STRINGS,
  USER_ID
} from "../../../../utils/constants";
import useSocket from "../../../../utils/hooks/sockets";
import { CreateNewCaseProps, NewCaseFormValues, SocketEmitFunction } from "../../../../utils/types";
import { ActionButton } from "../../../ActionButton";
import SelectComponent from "../../../SelectComponent";
import TextInput from "../../../TextInput";
import { updateCaseDetails, mapSelectedCaseToFormValues } from "./helper";

const NewCaseForm: React.FC<CreateNewCaseProps> = ({ caseData }) => {
  const { socketEmit } = useSocket();
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm, setValues, isValid } =
    useFormikContext<NewCaseFormValues>();
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCase) {
      const newFormValues = mapSelectedCaseToFormValues(selectedCase, values);
      setValues(newFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCase, setValues]);

  const handleSaveClick = useCallback(() => {
    if (!isValid) {
      toast.error(STRINGS.FILL_REQUIRED_FIELDS);
      console.log({ errors });
      return;
    }

    updateCaseDetails(socketEmit as SocketEmitFunction, values, USER_ID, caseData?.id);
    handleSubmit();
  }, [caseData?.id, errors, handleSubmit, isValid, socketEmit, values]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CASE_NAME}
          name={FORM_NAMES.CASE_NAME}
          errorMessage={errors?.caseName}
          value={values?.caseName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.COURT_CASE_NUMBER}
          name={FORM_NAMES.COURT_CASE_NUMBER}
          errorMessage={errors?.courtCaseNumber}
          value={values?.courtCaseNumber}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CLIENT_NAME}
          name={FORM_NAMES.CLIENT_NAME}
          errorMessage={errors?.clientName}
          value={values?.clientName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <SelectComponent
          label={FORM_LABELS.CASE_STATUS}
          name={FORM_NAMES.CASE_STATUS}
          errorMessage={errors?.caseStatus}
          items={CaseStatuses}
          value={values?.caseStatus}
          onChange={(e) => setFieldValue(FORM_NAMES.CASE_STATUS, e.target.value)}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.COURT_JURISDICTION}
          name={FORM_NAMES.COURT_JURISDICTION}
          errorMessage={errors?.courtJurisdiction}
          value={values?.courtJurisdiction}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.CASE_TYPE}
          name={FORM_NAMES.CASE_TYPE}
          errorMessage={errors?.caseType}
          value={values?.caseType}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.DEFENDANT_NAME}
          name={FORM_NAMES.DEFENDANT_NAME}
          errorMessage={errors?.defendantName}
          value={values?.defendantName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.OPPOSING_COUNSEL_NAME}
          name={FORM_NAMES.OPPOSING_COUNSELS_NAME}
          errorMessage={errors?.opposingCounselsName}
          value={values?.opposingCounselsName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <ActionButton title={BUTTON_TITLE.SAVE} className='save-button' onClick={handleSaveClick} />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            navigate(-1);
            resetForm();
          }}
          className='cancel-button'
        />
      </div>
    </Form>
  );
};

export default NewCaseForm;
