import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { BreadCrumbs } from "../../components";
import NavigationWrapper from "../../components/NavigationWrapper";
import AddNewPerson from "../../components/Pages/AddNewPerson";
import { addJuror } from "../../redux/slices/jurorSlice";
import { AppDispatch } from "../../redux/types";
import { TITLES } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { AddNewFormValues } from "../../utils/types";

import "./styles.scss";
const AddNewJuror: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const onSubmitJurorForm = useCallback(
    (values: AddNewFormValues) => {
      dispatch(addJuror(values));
      toast.success("Juror added successfully");
    },
    [dispatch]
  );

  return (
    <div className='d-flex flex-column'>
      <BreadCrumbs
        breadcrumbItems={[
          {
            text: "Manage Case",
            url: routes.MANAGE_CASE
          },
          { text: "Case Jurors", url: location.pathname }
        ]}
      />
      <NavigationWrapper title={TITLES.ADD_NEW_JUROR}>
        <AddNewPerson isJuror onSubmit={onSubmitJurorForm} />
      </NavigationWrapper>
    </div>
  );
};

export default AddNewJuror;
