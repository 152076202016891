import * as Yup from "yup";
import { ClientInformationFormValues, LegalAndOtherRecordsValues } from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  pronouns: Yup.string().optional(),
  preferredTitle: Yup.string().optional(),
  age: Yup.number().optional().positive().integer(),
  sex: Yup.string().optional(),
  gender: Yup.string().optional(),
  occupation: Yup.string().optional(),
  educationLevel: Yup.string().optional(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  country: Yup.string().optional(),
  zipCode: Yup.string().optional(),
  relationshipStatus: Yup.string().optional()
});

export const initialValues: ClientInformationFormValues = {
  firstName: "",
  lastName: "",
  nickname: "",
  age: "",
  sex: "",
  gender: "",
  pronouns: "",
  preferredTitle: "",
  occupation: "",
  educationLevel: "",
  address: "",
  addressAdditional: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  children: false,
  childrenText: "",
  childSupport: false,
  childSupportText: "",
  relationshipStatusOption: "",
  relationshipStatus: "",
  additionalInformation: "",
  timeZone: ""
};

export const demographicsValidationSchema = Yup.object().shape({
  ethnicity: Yup.string().optional(),
  economicStatus: Yup.string().optional(),
  occupation: Yup.string().optional(),
  workplace: Yup.string().optional(),
  anyBankruptcy: Yup.boolean(),
  anyBankruptcyText: Yup.string(),
  estimatedHouseholdIncome: Yup.string().optional(),
  estimatedNetWorth: Yup.string().optional(),
  lengthOfResidence: Yup.string().optional(),
  homeOwnershipStatus: Yup.string().optional()
});

export const initialDemographicsValues = {
  ethnicity: "",
  economicStatus: "",
  occupation: "",
  workplace: "",
  anyBankruptcy: false,
  anyBankruptcyText: "",
  estimatedHouseholdIncome: "",
  estimatedNetWorth: "",
  lengthOfResidence: "",
  homeOwnershipStatus: ""
};

export const legalAndOtherRecordsValidationSchema = Yup.object().shape({
  legal_other_records: Yup.string().optional(),
  licenses_or_credentials: Yup.string().optional(),
  academic_records: Yup.string().optional(),
  criminal_records: Yup.string().optional(),
  testimony_transcripts: Yup.string().optional(),
  prior_verdicts_or_settlements: Yup.string().optional(),
  witness_protection_program: Yup.string().optional(),
  no_contact_or_restraining_order: Yup.string().optional(),
  additional_information: Yup.string().notRequired(),
  prior_statements_or_reports: Yup.string().optional(),
  // Switch checks are not required, but we still include them for completeness
  prior_verdicts_or_settlements_check: Yup.boolean(),
  witness_protection_program_check: Yup.boolean(),
  no_contact_or_restraining_order_check: Yup.boolean(),
  prior_statements_or_reports_check: Yup.boolean()
});

export const initialLegalAndOtherRecordsValues: LegalAndOtherRecordsValues = {
  legal_other_records: "",
  licenses_or_credentials: "",
  academic_records: "",
  criminal_records: "",
  testimony_transcripts: "",
  prior_verdicts_or_settlements: "",
  witness_protection_program: "",
  no_contact_or_restraining_order: "",
  additionalInformation: "",
  prior_statements_or_reports: "",
  // Switch checks
  prior_verdicts_or_settlements_check: false,
  witness_protection_program_check: false,
  no_contact_or_restraining_order_check: false,
  prior_statements_or_reports_check: false
};

export const socialMediaValidationSchema = Yup.object().shape({
  twitter: Yup.string().url("Invalid URL"),
  facebook: Yup.string().url("Invalid URL"),
  reddit: Yup.string().url("Invalid URL"),
  tiktok: Yup.string().url("Invalid URL"),
  website_blog_urls: Yup.string().url("Invalid URL").optional(),
  linkedin: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
  youtube: Yup.string().url("Invalid URL")
});

export const initialSocialMediaValues = {
  twitter: "",
  facebook: "",
  reddit: "",
  tiktok: "",
  website_blog_urls: "",
  linkedin: "",
  instagram: "",
  youtube: ""
};
