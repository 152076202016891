import { useFormik } from "formik";
import React, { useCallback } from "react";
import "../jurorEvaluation.scss";
import {
  BUTTON_TITLE,
  FORM_LABELS,
  FORM_NAMES,
  jurorEvaluationTabsData,
  navCards,
  TITLES
} from "../../../../../utils/constants";
import { JurorEvaluationProps, RatingValue } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import RatingField from "../../../../RatingField";
import WrapperComponent from "../../../../WrapperComponent";
import { communicationSkillsInitialValues, communicationSkillsValidationSchema } from "../helper";

const CommunicationSkills: React.FC<JurorEvaluationProps> = ({ handleTabChange, setActiveCard }) => {
  const formik = useFormik({
    initialValues: communicationSkillsInitialValues,
    validationSchema: communicationSkillsValidationSchema,
    onSubmit: (values) => {
      console.log("Form data", values);
    }
  });

  const { values, setFieldValue, resetForm, handleSubmit } = formik;

  const handleRatingChange = useCallback(
    (field: string, value: RatingValue) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleCommentChange = useCallback(
    (field: string, comment: string) => {
      setFieldValue(field, comment);
    },
    [setFieldValue]
  );

  return (
    <div className='juror-evaluation-container'>
      <WrapperComponent title={TITLES.COMMUNICATION_SKILLS}>
        <form onSubmit={handleSubmit}>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.CLARITY_OF_EXPRESSION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.CLARITY_OF_EXPRESSION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.CLARITY_OF_EXPRESSION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.CLARITY_OF_EXPRESSION] as RatingValue}
              value={values[FORM_NAMES.CLARITY_OF_EXPRESSION_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.ARTICULATION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ARTICULATION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ARTICULATION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ARTICULATION] as RatingValue}
              value={values[FORM_NAMES.ARTICULATION_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.ACTIVE_LISTENING}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ACTIVE_LISTENING, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ACTIVE_LISTENING_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ACTIVE_LISTENING] as RatingValue}
              value={values[FORM_NAMES.ACTIVE_LISTENING_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.NON_VERBAL_COMMUNICATION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.NON_VERBAL_COMMUNICATION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.NON_VERBAL_COMMUNICATION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.NON_VERBAL_COMMUNICATION] as RatingValue}
              value={values[FORM_NAMES.NON_VERBAL_COMMUNICATION_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.RESPECTFUL_COMMUNICATION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.RESPECTFUL_COMMUNICATION, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.RESPECTFUL_COMMUNICATION_COMMENT, comment)}
              initialRating={values[FORM_NAMES.RESPECTFUL_COMMUNICATION] as RatingValue}
              value={values[FORM_NAMES.RESPECTFUL_COMMUNICATION_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.CONFLICT_RESOLUTION_SKILLS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.CONFLICT_RESOLUTION_SKILLS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.CONFLICT_RESOLUTION_SKILLS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.CONFLICT_RESOLUTION_SKILLS] as RatingValue}
              value={values[FORM_NAMES.CONFLICT_RESOLUTION_SKILLS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.PERSUASIVENESS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.PERSUASIVENESS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.PERSUASIVENESS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.PERSUASIVENESS] as RatingValue}
              value={values[FORM_NAMES.PERSUASIVENESS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.QUESTIONING_SKILLS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.QUESTIONING_SKILLS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.QUESTIONING_SKILLS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.QUESTIONING_SKILLS] as RatingValue}
              value={values[FORM_NAMES.QUESTIONING_SKILLS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.ADAPTABILITY_IN_COMMUNICATION}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ADAPTABILITY_IN_COMMUNICATION, value)}
              onCommentChange={(comment) =>
                handleCommentChange(FORM_NAMES.ADAPTABILITY_IN_COMMUNICATION_COMMENT, comment)
              }
              initialRating={values[FORM_NAMES.ADAPTABILITY_IN_COMMUNICATION] as RatingValue}
              value={values[FORM_NAMES.ADAPTABILITY_IN_COMMUNICATION_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.NOTE_TAKING_SKILLS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.NOTE_TAKING_SKILLS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.NOTE_TAKING_SKILLS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.NOTE_TAKING_SKILLS] as RatingValue}
              value={values[FORM_NAMES.NOTE_TAKING_SKILLS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
            <ActionButton
              title={BUTTON_TITLE.SAVE}
              className='save-button'
              onClick={() => {
                setActiveCard?.(navCards[2]);
                resetForm();
              }}
            />
            <ActionButton
              title={BUTTON_TITLE.CANCEL}
              onClick={() => {
                handleTabChange?.(jurorEvaluationTabsData[2]);
                resetForm();
              }}
              className='cancel-button'
            />
          </div>
        </form>
      </WrapperComponent>
    </div>
  );
};

export default CommunicationSkills;
