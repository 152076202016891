import { useState } from "react";
import { FormConfiguratorProps, InputField } from "../../utils/types";
import SearchInput from "../AdminPortalLayout/SearchInput";
import DraggableInputField from "./components/DraggableInputField";
import "./formConfigurator.scss";

const FormConfigurator = ({ inputFields, moveField }: FormConfiguratorProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query: string) => {
    setSearchQuery(query.toLowerCase());
  };

  // Filter input fields based on the search query
  const filteredFields = inputFields.filter((field: InputField) => {
    // Search based on field label, name, or placeholder
    return (
      field.label.toLowerCase().includes(searchQuery) ||
      field.name.toLowerCase().includes(searchQuery) ||
      (field.placeholder && field.placeholder.toLowerCase().includes(searchQuery))
    );
  });

  return (
    <div className='form-configurator'>
      <div className='d-flex justify-content-between align-items-center'>
        <h6 className='font-size-18 color-primary-light fw-bold align-item-center'>Configurator</h6>
        <SearchInput
          inputClassName='bg-white'
          handleSearch={handleSearch}
          searchPlaceholder='Search fields'
          shouldRenderSuggestions={false}
        />
      </div>
      <h6 className='font-size-14 color-primary-dark mb-3'>Add fields by dragging them from list</h6>

      {filteredFields.length > 0 ? (
        filteredFields.map(
          (field: InputField, index: number) =>
            field.type !== "select" && (
              <DraggableInputField
                moveField={moveField}
                currentIndex={index}
                isConfigureMode
                allDisabled
                key={field.name}
                field={field}
              />
            )
        )
      ) : (
        <p>No fields found.</p>
      )}
    </div>
  );
};

export default FormConfigurator;
