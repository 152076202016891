import { Form, useFormikContext } from "formik";
import React from "react";
import { LegalAndOtherRecordsValues, NewClientFormLegalProps } from "../../../../../utils/types";
import LegalAndOtherRecords from "../../../LegalAndOtherRecords";

const NewClientFormLegal: React.FC<NewClientFormLegalProps> = ({ handleTabChange }) => {
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm } =
    useFormikContext<LegalAndOtherRecordsValues>();

  return (
    <Form onSubmit={handleSubmit}>
      <LegalAndOtherRecords
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        resetForm={resetForm}
        handleTabChange={handleTabChange}
      />
    </Form>
  );
};

export default NewClientFormLegal;
