import { ColDef, ICellRendererParams } from "ag-grid-community";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ACTION_COLUMN_STYLE, BUTTON_TITLE, baseCaseEvidenceColumns } from "../../../../utils/constants";
import routes from "../../../../utils/constants/routes";
import { CaseDocumentsProps } from "../../../../utils/types";
import { ActionButton } from "../../../ActionButton";
import AgGrid from "../../../AgGrid";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import { WrapperComponent } from "../../../WrapperComponent";

const CaseDocuments: React.FC<CaseDocumentsProps> = ({ documentsList, onDelete, onDownload }) => {
  const navigate = useNavigate();

  const DocumentsColumns: ColDef[] = [
    ...baseCaseEvidenceColumns,
    {
      ...ACTION_COLUMN_STYLE,
      cellStyle: { borderColor: "transparent" },
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer editable={false} data={params.data} onDelete={onDelete} download onEdit={onDownload} />
      )
    }
  ];

  return (
    <div className='d-flex h-100 flex-column'>
      <WrapperComponent title='Other Documents' customClass={"mb-2"}>
        <div className='d-flex' style={{ height: "60vh" }}>
          <AgGrid columnDefs={DocumentsColumns} rowData={documentsList} />
        </div>
      </WrapperComponent>

      <ActionButton
        className='save-button continue-button mt-2 mb-2'
        onClick={() => {
          navigate(routes.CASES_LIST);
        }}
        title={BUTTON_TITLE.CONTINUE}
      />
    </div>
  );
};

export default CaseDocuments;
