export const SOCKET_EVENTS = {
  // Core connection/disconnection events
  CONNECT: "connect", // Client successfully connects to the server
  DISCONNECT: "disconnect", // Client disconnects from the server
  CONNECT_ERROR: "connect_error", // Client fails to connect to the server
  ERROR: "error", // General error event
  ANERVA_SERVER_RESPONSE: "anervaServerResponse",
  ANERVA_SERVER_REQUEST: "anervaServerRequest",
  RECONNECT: "reconnect",
  RECONNECT_ATTEMPT: "reconnect_attempt",
  RECONNECT_FAILED: "reconnect_failed",
  // Cases
  MANAGE_CASE: "manageCase",
  // Types
  FETCH_CASES: "fetchCases",
  ADD_CASE: "addCase",
  DELETE_CASE: "deleteCase",
  // Events
  CASES_DATA: "casesData",
  CASE_ADDED: "caseAdded",
  CASE_DELETED: "caseDeleted",
  CASE_UPDATED: "caseUpdated",
  CASE_SEARCHED: "caseSearched",

  // Participants
  MANAGE_PARTICIPANT: "manageParticipant",
  // Types
  ADD_PARTICIPANT: "addParticipant",
  DELETE_PARTICIPANT: "deleteParticipant",
  FETCH_PARTICIPANT: "fetchParticipant",
  RATE_PARTICIPANT: "rateParticipant",
  PARTICIPANT_RATED: "participantRated",
  // Events
  PARTICIPANT_DATA: "participantData",
  PARTICIPANT_ADDED: "participantAdded",
  PARTICIPANT_DELETED: "participantDeleted",
  PARTICIPANT_DATA_TRIAL: "participantDataTrial",
  PARTICIPANT_DATA_NON_TRIAL: "participantDataNonTrial",

  // Case Participants
  MANAGE_CASE_PARTICIPANT: "manageCaseParticipant",
  // Types
  FETCH_CASE_PARTICIPANTS: "fetchCaseParticipants",
  ADD_CASE_PARTICIPANT: "addCaseParticipant",
  DELETE_CASE_PARTICIPANT: "deleteCaseParticipant",
  // Events
  CASE_PARTICIPANT_ADDED: "caseParticipantAdded",
  CASE_PARTICIPANTS_DATA: "caseParticipantsData",
  CASE_PARTICIPANT_DATA: "caseParticipantData",
  CASE_PARTICIPANT_DELETED: "caseParticipantDeleted",
  NON_TRIAL_PARTICIPANTS: "nonTrialParticipant",
  TRIAL_PARTICIPANTS: "trialParticipants",

  // Case Trial Participants
  MANAGE_CASE_TRIAL_PARTICIPANT: "manageCaseTrialParticipant",
  // Types
  FETCH_CASE_TRIAL_PARTICIPANT_SINGLE: "fetchSingleCaseTrialParticipant",
  FETCH_CASE_TRIAL_PARTICIPANTS: "fetchCaseTrialParticipants",
  FETCH_CASE_NON_TRIAL_PARTICIPANTS: "fetchCaseNonTrialParticipants",
  ADD_CASE_TRIAL_PARTICIPANT: "addCaseTrialParticipant",
  DELETE_CASE_TRIAL_PARTICIPANT: "deleteCaseTrialParticipant",
  KICK_OUT_CASE_TRIAL_PARTICIPANT: "kickOutCaseTrialParticipant",

  // Events
  CASE_TRIAL_PARTICIPANT_ADDED: "caseTrialParticipantAdded",
  CASE_TRIAL_PARTICIPANTS_DATA: "caseTrialParticipantsData",
  CASE_TRIAL_PARTICIPANT_DELETED: "caseTrialParticipantDeleted",

  CASE_PARTICIPANT_DELETE_TRIAL: "caseTrialParticipantDelete",
  FETCH_CASE_PARTICIPANT_BY_TYPE: "caseTrialParticipant",
  CASE_PARTICIPANT_KICKED_OUT: "caseParticipantKickedOut",

  // Participant Rating
  MANAGE_PARTICIPANT_RATING: "manageParticipantRating",
  // Types
  ADD_PARTICIPANT_RATING: "addParticipantRating",
  FETCH_PARTICIPANT_RATINGS: "fetchParticipantRatings",
  FETCH_SINGLE_PARTICIPANT_RATING: "fetchSingleParticipantRating",
  DELETE_PARTICIPANT_RATING: "deleteParticipantRating",
  UPDATE_COURT_DATE: "addUpdateCourtDate",
  // Events
  PARTICIPANT_RATING_ADDED: "participantRatingAdded",
  PARTICIPANT_RATINGS_DATA: "participantRatingsData",
  PARTICIPANT_RATING_DATA: "participantRatingData",
  PARTICIPANT_RATING_DELETED: "participantRatingDeleted",

  MANAGE_COURT_DATE: "manageCourtDate",
  //Events
  COURT_DATES_DATA: "courtDatesData",
  COURT_DATE_ADDED: "courtDateAdded",
  COURT_DATE_UPDATED: "courtDateAddedOrUpdated",
  COURT_DATE_DELETED: "courtDateDeleted",
  COURT_DATE_ADD_OR_UPDATE: "courtDateAddedOrUpdated",

  //Types
  FETCH_COURT_DATES: "fetchCourtDates",
  ADD_COURT_DATE: "addCourtDate",
  DELETE_COURT_DATE: "deleteCourtDate",
  ADD_UPDATE_COURT_DATE: "addUpdateCourtDate",

  //CaseDetails
  MANAGE_CASE_DETAILS: "manageCaseDetails",
  //Types
  FETCH_CASE_DETAILS: "fetchCaseDetails",
  ADD_CASE_DETAILS: "addCaseDetails",
  UPDATE_CASE_DETAILS: "updateCaseDetails",
  DELETE_CASE_DETAILS: "deleteCaseDetails",
  FETCH_CASE_DETAILS_BY_PARAMS: "fetchCaseDetailsByParams",
  UPDATE_CASE_AND_DETAILS: "updateCaseAndDetails",
  //Events
  CASE_DETAILS_DATA: "caseDetailsData",
  CASE_DETAILS_ADDED: "caseDetailsAdded",
  CASE_DETAILS_UPDATED: "caseDetailsUpdated",
  CASE_DETAILS_DELETED: "caseDetailsDeleted",
  CASE_AND_DETAILS_UPDATED: "caseAndDetailsUpdated",

  MANAGE_PARTICIPANT_DETAILS: "manageParticipantDetails",
  //Events
  PARTICIPANT_DETAILS_FETCHED: "participantDetailsFetched",
  PARTICIPANT_DETAILS_ADDED: "participantDetailsAdded",
  PARTICIPANT_DETAILS_UPDATED: "participantDetailsUpdated",
  PARTICIPANT_DETAILS_DELETED: "participantDetailsDeleted",

  PARTICIPANT_AND_DETAILS_UPDATED: "participantAndDetailsUpdated",
  PARTICIPANT_SEARCHED: "participantSearched",
  SEARCH_PARTICIPANTS: "searchParticipants",
  FETCH_PARTICIPANT_DETAILS: "fetchParticipantDetails",
  ADD_PARTICIPANT_DETAILS: "addParticipantDetails",
  UPDATE_PARTICIPANT_DETAILS: "updateParticipantDetails",
  DELETE_PARTICIPANT_DETAILS: "deleteParticipantDetails",
  FETCH_PARTICIPANT_TRIAL: "fetchParticipantTrial",
  FETCH_PARTICIPANT_NON_TRIAL: "fetchParticipantNonTrial"
};
