import React, { useCallback, useState } from "react";
import { personalDataPageTabs } from "../../../../utils/constants";
import { PersonalDataProps, Tab } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";
import BasicInformation from "./BasicInformation";
import CommunicationPreferences from "./CommunicationPreferences";
import DemographicInformation from "./DemographicInformation";
import FinancialInformation from "./FinancialInformation";
import LegalRecordsInformation from "./LegalRecords";
import PersonalPreferences from "./PersonalPreferences";
import PoliticalAndSocialDetails from "./PoliticalAndSocial";
import { SMAndOnlinePresence } from "./SocialMediaAndOnlinePresence";

export const PersonalData: React.FC<PersonalDataProps> = ({ setActiveCard }) => {
  const [activeTab, setActiveTab] = useState<Tab>(personalDataPageTabs[0]);

  const handleTabChange = useCallback((tab: Tab = personalDataPageTabs[0]) => {
    setActiveTab(tab);
  }, []);

  const renderTabSection = (activeTabId: number) => {
    switch (activeTabId) {
      case 1:
        return <BasicInformation setActiveCard={setActiveCard} handleTabChange={handleTabChange} />;
      case 2:
        return <DemographicInformation handleTabChange={handleTabChange} />;
      case 3:
        return <LegalRecordsInformation handleTabChange={handleTabChange} />;
      case 4:
        return <SMAndOnlinePresence handleTabChange={handleTabChange} />;
      case 5:
        return <PersonalPreferences handleTabChange={handleTabChange} />;
      case 6:
        return <PoliticalAndSocialDetails handleTabChange={handleTabChange} />;
      case 7:
        return <FinancialInformation handleTabChange={handleTabChange} />;
      case 8:
        return <CommunicationPreferences setActiveCard={setActiveCard} handleTabChange={handleTabChange} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <TabButtons onTabChange={(tab) => setActiveTab(tab)} tabs={personalDataPageTabs} activeTab={activeTab} />
      {renderTabSection(activeTab.id)}
    </div>
  );
};
