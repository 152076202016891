import React, { useState } from "react";
import { trialTeamTabButtons } from "../../../../utils/constants";
import { CaseTrialTeamProps, Tab } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";
import { JuryBox, TrialTeamStatistics, TrialTeamTab } from "../../TrialTeam";

const CaseTrialTeam: React.FC<CaseTrialTeamProps> = () => {
  const [activeTab, setActiveTab] = useState<Tab | undefined>(trialTeamTabButtons[0]);

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case trialTeamTabButtons[0]:
        return <TrialTeamTab />;
      case trialTeamTabButtons[1]:
        return <JuryBox />;
      case trialTeamTabButtons[2]:
        return <TrialTeamStatistics />;
      default:
        return null;
    }
  };

  return (
    <div className='d-flex h-100 flex-column'>
      <TabButtons
        tabs={trialTeamTabButtons}
        activeTab={activeTab || trialTeamTabButtons[0]}
        onTabChange={(tab) => {
          setActiveTab(tab);
        }}
      />
      {renderActiveTabContent()}
    </div>
  );
};

export default CaseTrialTeam;
