import { Form, useFormikContext } from "formik";
import React from "react";
import { DemographicsFormValues, NewClientFormDemoProps } from "../../../../../utils/types";
import Demographics from "../../../Demographics";

const NewClientDemographics: React.FC<NewClientFormDemoProps> = ({ handleTabChange }) => {
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm } =
    useFormikContext<DemographicsFormValues>();

  return (
    <Form onSubmit={handleSubmit}>
      <Demographics
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        handleTabChange={handleTabChange}
        resetForm={resetForm}
      />
    </Form>
  );
};

export default NewClientDemographics;
