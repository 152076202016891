import { useFormik } from "formik";
import React, { useCallback } from "react";
import "../jurorProfile.scss";
import { BUTTON_TITLE, FORM_LABELS, FORM_NAMES, jurorProfileTabsData, TITLES } from "../../../../../utils/constants";
import { JurorEvaluationProps, RatingValue } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import RatingField from "../../../../RatingField";
import WrapperComponent from "../../../../WrapperComponent";
import { weaknessesInitialValues, weaknessesValidationSchema } from "../helper";

const Weaknesses: React.FC<JurorEvaluationProps> = ({ handleTabChange }) => {
  const formik = useFormik({
    initialValues: weaknessesInitialValues,
    validationSchema: weaknessesValidationSchema,
    onSubmit: (values) => {
      console.log("Form data", values);
    }
  });

  const { values, setFieldValue, resetForm, handleSubmit } = formik;

  const handleRatingChange = useCallback(
    (field: string, value: RatingValue) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleCommentChange = useCallback(
    (field: string, comment: string) => {
      setFieldValue(field, comment);
    },
    [setFieldValue]
  );

  return (
    <div className='juror-evaluation-container'>
      <WrapperComponent title={TITLES.WEAKNESS}>
        <form onSubmit={handleSubmit}>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.TECHNICAL_SKILL_GAPS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.TECHNICAL_SKILL_GAPS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.TECHNICAL_SKILL_GAPS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.TECHNICAL_SKILL_GAPS] as RatingValue}
              value={values[FORM_NAMES.TECHNICAL_SKILL_GAPS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.COMMUNICATION_CHALLENGES}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.COMMUNICATION_CHALLENGES, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.COMMUNICATION_CHALLENGES_COMMENT, comment)}
              initialRating={values[FORM_NAMES.COMMUNICATION_CHALLENGES] as RatingValue}
              value={values[FORM_NAMES.COMMUNICATION_CHALLENGES_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.ADAPTABILITY_TO_CHANGE}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ADAPTABILITY_TO_CHANGE, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ADAPTABILITY_TO_CHANGE_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ADAPTABILITY_TO_CHANGE] as RatingValue}
              value={values[FORM_NAMES.ADAPTABILITY_TO_CHANGE_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.TIME_MANAGEMENT_ISSUES}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.TIME_MANAGEMENT_ISSUES, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.TIME_MANAGEMENT_ISSUES_COMMENT, comment)}
              initialRating={values[FORM_NAMES.TIME_MANAGEMENT_ISSUES] as RatingValue}
              value={values[FORM_NAMES.TIME_MANAGEMENT_ISSUES_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.STRESS_MANAGEMENT}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.STRESS_MANAGEMENT, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.STRESS_MANAGEMENT_COMMENT, comment)}
              initialRating={values[FORM_NAMES.STRESS_MANAGEMENT] as RatingValue}
              value={values[FORM_NAMES.STRESS_MANAGEMENT_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.CLIENT_RELATIONSHIP_MANAGEMENT}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.CLIENT_RELATIONSHIP_MANAGEMENT, value)}
              onCommentChange={(comment) =>
                handleCommentChange(FORM_NAMES.CLIENT_RELATIONSHIP_MANAGEMENT_COMMENT, comment)
              }
              initialRating={values[FORM_NAMES.CLIENT_RELATIONSHIP_MANAGEMENT] as RatingValue}
              value={values[FORM_NAMES.CLIENT_RELATIONSHIP_MANAGEMENT_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.PROBLEM_SOLVING_SKILLS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.PROBLEM_SOLVING_SKILLS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.PROBLEM_SOLVING_SKILLS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.PROBLEM_SOLVING_SKILLS] as RatingValue}
              value={values[FORM_NAMES.PROBLEM_SOLVING_SKILLS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.UNDERSTANDING_LAW_PROCESSES}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.UNDERSTANDING_LAW_PROCESSES, value)}
              onCommentChange={(comment) =>
                handleCommentChange(FORM_NAMES.UNDERSTANDING_LAW_PROCESSES_COMMENT, comment)
              }
              initialRating={values[FORM_NAMES.UNDERSTANDING_LAW_PROCESSES] as RatingValue}
              value={values[FORM_NAMES.UNDERSTANDING_LAW_PROCESSES_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
            <ActionButton
              title={BUTTON_TITLE.SAVE}
              className='save-button'
              onClick={() => {
                handleTabChange?.(jurorProfileTabsData[2]);
                resetForm();
              }}
            />
            <ActionButton
              title={BUTTON_TITLE.CANCEL}
              onClick={() => {
                handleTabChange?.(jurorProfileTabsData[0]);
                resetForm();
              }}
              className='cancel-button'
            />
          </div>
        </form>
      </WrapperComponent>
    </div>
  );
};

export default Weaknesses;
