import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ActionButton, BreadCrumbs } from "../../components";
import DashboardCard from "../../components/DashboardCard";
import TabButtons from "../../components/TabButtons";
import TaskButton from "../../components/TaskButton/TaskButton";
import { setSelectedCase } from "../../redux/slices/casesSlices";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { AppDispatch, RootState } from "../../redux/types";
import { BUTTON_TITLE, dashboardCards, dashboardTabButtons, TITLES, USER_ID, variants } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../utils/constants/socketEvents";
import useSocket from "../../utils/hooks/sockets";
import { CaseListData, DashboardCardType, Tab } from "../../utils/types";
import { getCaseCardVariant } from "./helper";
import "./styles.dashboard.scss";

const Dashboard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<Tab>(dashboardTabButtons[0]);
  const location = useLocation();
  const caseList = useSelector((state: RootState) => state.cases.caseList);
  const total = useSelector((state: RootState) => state.cases.total);

  const dispatch = useDispatch<AppDispatch>();
  const { socket, socketEmit, socketOff } = useSocket();
  const fetchedRef = useRef(false);

  const fetchAllCasesFromServer = useCallback(() => {
    if (fetchedRef.current) return;
    if (socket) {
      socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, {
        type: SOCKET_EVENTS.FETCH_CASES,
        eventType: SOCKET_EVENTS.MANAGE_CASE,
        userId: USER_ID,
        requestId: uuidv4()
      });
    }
    fetchedRef.current = true; // Mark as fetched
  }, [socket, socketEmit]);

  useEffect(() => {
    fetchAllCasesFromServer();
    return () => {
      if (socket) {
        socketOff(SOCKET_EVENTS.CASES_DATA);
      }
    };
  }, [socket, dispatch, fetchAllCasesFromServer, socketOff]);

  const filteredCases = useMemo(() => {
    return caseList?.filter((caseItem: CaseListData) => activeTab?.value === caseItem.status) || [];
  }, [caseList, activeTab]);

  const displayCount = 3;

  const handleShowAllClick = useCallback(() => {
    navigate(routes.CASES_LIST);
  }, [navigate]);

  const handleTaskButtonClick = useCallback(
    (caseItem: CaseListData) => {
      dispatch(setSelectedCase(caseItem));
      navigate(routes.MANAGE_CASE, { state: { caseData: caseItem, from: routes.DASHBOARD } });
    },
    [dispatch, navigate]
  );

  return (
    <div className='dashboard-page'>
      <div>
        <BreadCrumbs
          breadcrumbItems={[
            {
              text: "dashboard",
              url: location.pathname === routes.HOME ? routes.HOME : routes.DASHBOARD
            }
          ]}
        />
      </div>
      <div className='action-row'>
        <span className='date-text'>{moment().format("dddd, MMMM Do, YYYY")}</span>
        <ActionButton
          title={BUTTON_TITLE.ADD_NEW_CASE}
          icon={<FontAwesomeIcon icon={"plus"} />}
          onClick={() => {
            navigate(routes.CREATE_CASE);
            dispatch(setActiveMenu(routes.CREATE_CASE || ""));
          }}
        />
      </div>

      <h1 className='heading1'>{TITLES.WELCOME_MSG}</h1>
      <div className='cards-row'>
        {dashboardCards.map((card: DashboardCardType, index: number) => (
          <DashboardCard
            key={index.toString()}
            title={card?.title}
            icon={<i className={card?.iconClassName}></i>}
            count={index === 0 ? total : card?.count}
            className={card?.className}
          />
        ))}
      </div>

      <TabButtons
        tabs={dashboardTabButtons}
        activeTab={activeTab || dashboardTabButtons[0]}
        onTabChange={setActiveTab}
      />

      {filteredCases.length > displayCount && (
        <div className='d-flex justify-content-end align-items-center pb-1'>
          <a onClick={handleShowAllClick} className='text-end '>
            <span className='color-primary-light fw-bold'>Show All</span>
          </a>
        </div>
      )}

      <div className='d-flex flex-row w-100 gap-3 flex-wrap'>
        {filteredCases.length > 0 ? (
          filteredCases
            .slice(0, displayCount)
            .map((caseItem: CaseListData, index: number) => (
              <TaskButton
                onClick={() => handleTaskButtonClick(caseItem)}
                key={caseItem.id || index.toString()}
                variant={activeTab?.value ? getCaseCardVariant(activeTab?.value) : variants.INFO}
                caseData={caseItem}
              />
            ))
        ) : (
          <h6 className='font-size-15 color-primary-dark m-3'>No cases found</h6>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
