import moment from "moment";
import React from "react";
import "./styles.taskbutton.scss";
import { caseCardLabels, dateFormats, variants } from "../../utils/constants";
import { CaseItem } from "../../utils/types";

export const TaskButton = ({
  caseData,
  variant = variants.INFO,
  onClick
}: {
  caseData?: CaseItem;
  variant?: string;
  onClick?: () => void;
}) => {
  return (
    <div className={`tb-wrapper ${variant}`} data-bs-theme='light' onClick={onClick}>
      <div className='left-bar' />
      <div className='tb-content'>
        <div className='top-content'>
          <div className='title'>{caseData?.caseName || ""}</div>
        </div>
        <div className='middle-content'>
          <div className={`middle-item`}>
            <div className='property-name'>{caseCardLabels.CASE_NAME}</div>
            <div className='property-value'>{caseData?.courtCaseNumber ?? "-"}</div>
          </div>
          <div className={`middle-item`}>
            <div className='property-name'>{caseCardLabels.COURT_JURISDICTION}</div>
            <div className='property-value'>{caseData?.caseDetails?.[0]?.courtJurisdiction ?? "-"}</div>
          </div>
          <div className={`middle-item`}>
            <div className='property-name'>{caseCardLabels.CLIENT_NAME}</div>
            <div className='property-value'>{caseData?.caseDetails?.[0]?.clientName ?? "-"}</div>
          </div>
          <div className={`middle-item last`}>
            <div className='property-name'>{caseCardLabels.TRIAL_DATE}</div>
            <div className='property-value'>
              {caseData?.courtDates?.[0]?.trialStartDate
                ? moment(caseData?.courtDates[0].trialStartDate).format(dateFormats.MM_DD_YYYY)
                : "-"}
            </div>
          </div>
        </div>
        <div className='bottom-content'>{caseCardLabels.N_A}</div>
      </div>
    </div>
  );
};

export default TaskButton;
