import React, { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BreadCrumbs } from "../../components";
import NavigationWrapper from "../../components/NavigationWrapper";
import { AddNewCase } from "../../components/Pages/CreateCase";
import ToggleButton from "../../components/ToggleButton/toggleButton";
import { FORM_LABELS, TITLES } from "../../utils/constants";
import routes from "../../utils/constants/routes";

const CreateCase: React.FC = () => {
  const [isConfigureMode, setIsConfigureMode] = useState<boolean>(false);
  const handleConfigureModeChange = useCallback((checked: boolean) => {
    setIsConfigureMode(checked);
  }, []);
  return (
    <div className='scrollable-container'>
      <BreadCrumbs breadcrumbItems={[{ text: "Create New Case", url: routes.CREATE_CASE }]} />
      <ToggleButton
        label={FORM_LABELS.CONFIGURE_MODE}
        isChecked={isConfigureMode}
        handleChange={handleConfigureModeChange}
      />
      <NavigationWrapper
        title={TITLES.CREATE_NEW_CASE}
        goBack={false}
        titleClass='color-primary-dark font-size-18 fw-bold'
      >
        <DndProvider backend={HTML5Backend}>
          <AddNewCase isConfigureMode={isConfigureMode} />
        </DndProvider>
      </NavigationWrapper>
    </div>
  );
};

export default CreateCase;
