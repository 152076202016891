import React, { useState } from "react";
import { BUTTON_TITLE } from "../../utils/constants";
import ActionButton from "../ActionButton";

import "./styles.MenuButton.scss";
const MenuButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = () => {
    setIsOpen(false); // Close the dropdown
  };

  return (
    <div className='dropdown'>
      <ActionButton
        title={BUTTON_TITLE.SHARE}
        className='bg-orange'
        onClick={toggleDropdown}
        icon={<i className='fa-regular fa-arrow-up-from-square'></i>}
      />
      {isOpen && (
        <div className='custom-dropdown-menu' aria-labelledby='dropdownMenuButton'>
          <div className='dropdown-item__share' onClick={handleOptionSelect}>
            {BUTTON_TITLE.Share}
            <i className='fa-regular fa-arrow-up-from-square'></i>
          </div>
          <div className='icon-title'>
            <i className='fa-regular fa-file-pdf red-icon'></i>
            <a className='dropdown-item' href='#' onClick={handleOptionSelect}>
              {BUTTON_TITLE.DOWNLOAD_AS_PDF}
            </a>
          </div>
          <div className='divider' />

          <div className='icon-title'>
            <i className='fa-regular fa-link-simple green-icon'></i>
            <a className='dropdown-item' href='#' onClick={handleOptionSelect}>
              {BUTTON_TITLE.SHARE_LINK_TO_THIS_PAGE}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuButton;
