import React, { useState, useCallback, useEffect } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import "./styles.dateInput.scss";
import { DateInputProps } from "../../utils/types";

/**
 * A reusable date input component that wraps the Flatpickr date picker.
 *
 * @param {string} label - The label text for the date input field.
 * @param {boolean} required - Whether the field is required. Default is `false`.
 * @param {string} placeholder - Placeholder text for the date input. Default is `"MM/DD/YYYY"`.
 * @param {Date} value - The current value of the date input. Default is `new Date()`.
 * @param {Date | string} minDate - The minimum selectable date.
 * @param {Date | string} maxDate - The maximum selectable date.
 * @param {string} parentClassName - Additional class name(s) for the parent div.
 * @param {string} errorMessage - Error message to display when input is invalid.
 * @param {(date: Date | null) => void} onDateChange - Callback function called when the date changes.
 * @param {React.InputHTMLAttributes<HTMLInputElement>} props - Additional props for the input field.
 * @returns {JSX.Element} The rendered date input component.
 */
const DateInput: React.FC<DateInputProps> = ({
  label,
  required = false,
  placeholder = "MM/DD/YYYY",
  onDateChange,
  value = new Date(),
  minDate,
  maxDate,
  parentClassName = "",
  errorMessage = "",
  ...props
}) => {
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    if (value) setDate(new Date(value));
  }, [value]);

  /**
   * Handles the date change event from the Flatpickr component.
   *
   * @param {Date[]} selectedDates - The selected dates from Flatpickr.
   */
  const handleDateChange = useCallback(
    (selectedDates: Date[]) => {
      const newDate = selectedDates[0] || null;
      setDate(newDate);
      if (onDateChange) {
        onDateChange(newDate);
      }
    },
    [onDateChange]
  );
  return (
    <div className={`form-group ${parentClassName}`}>
      <label className='form-label'>
        {label}
        {required && <span className='required-marker'> *</span>}
      </label>
      <div className='date-field'>
        <div className='input-group'>
          <Flatpickr
            className='form-control'
            value={date}
            onChange={handleDateChange}
            options={{
              dateFormat: "m/d/Y", // MM/DD/YYYY format
              minDate: minDate, // Minimum date that can be selected
              maxDate: maxDate // Maximum date that can be selected
            }}
            placeholder={placeholder}
            {...props}
          />
        </div>
        <span className='calendar-icon'>
          <i className='fa-regular fa-calendar'></i>
        </span>
      </div>
      <div className='invalid-feedback'>{errorMessage || `${label} is Required!`}</div>
    </div>
  );
};

export default DateInput;
