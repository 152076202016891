import React from "react";
import { Link } from "react-router-dom";
import "./styles.notFound.scss";
import routes from "../../utils/constants/routes";

const NotFound = () => {
  return (
    <div className='not-found-container'>
      <h1 className='not-found-title'>404</h1>
      <p className='not-found-message'>Oops! The page you&apos;re looking for doesn&apos;t exist.</p>
      <Link to={routes.DASHBOARD} className='home-link'>
        Go back to the dashboard
      </Link>
    </div>
  );
};

export default NotFound;
