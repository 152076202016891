import * as Yup from "yup";
import { FORM_NAMES } from "../../../../utils/constants";
import { RatingValue } from "../../../../utils/types";

export const strengthsInitialValues = {
  [FORM_NAMES.TECHNICAL_SKILLS]: 0,
  [FORM_NAMES.TECHNICAL_SKILLS_COMMENT]: "",
  [FORM_NAMES.LIFE_EXPERIENCES]: 0,
  [FORM_NAMES.LIFE_EXPERIENCES_COMMENT]: "",
  [FORM_NAMES.LEADERSHIP_QUALITIES]: 0,
  [FORM_NAMES.LEADERSHIP_QUALITIES_COMMENT]: "",
  [FORM_NAMES.COMMUNICATION]: 0,
  [FORM_NAMES.COMMUNICATION_COMMENT]: "",
  [FORM_NAMES.DECISION_MAKING]: 0,
  [FORM_NAMES.DECISION_MAKING_COMMENT]: "",
  [FORM_NAMES.SOFT_SKILLS]: 0,
  [FORM_NAMES.SOFT_SKILLS_COMMENT]: "",
  [FORM_NAMES.ACHIEVEMENTS]: 0,
  [FORM_NAMES.ACHIEVEMENTS_COMMENT]: "",
  [FORM_NAMES.RESILIENCE]: 0,
  [FORM_NAMES.RESILIENCE_COMMENT]: "",
  [FORM_NAMES.ANALYTICAL_SKILLS]: 0,
  [FORM_NAMES.ANALYTICAL_SKILLS_COMMENT]: "",
  [FORM_NAMES.TEAMWORK]: 0,
  [FORM_NAMES.TEAMWORK_COMMENT]: ""
};

export const strengthsValidationSchema = Yup.object({
  [FORM_NAMES.TECHNICAL_SKILLS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.TECHNICAL_SKILLS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.LIFE_EXPERIENCES]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.LIFE_EXPERIENCES_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.LEADERSHIP_QUALITIES]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.LEADERSHIP_QUALITIES_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.COMMUNICATION]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.COMMUNICATION_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.DECISION_MAKING]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.DECISION_MAKING_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.SOFT_SKILLS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.SOFT_SKILLS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ACHIEVEMENTS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.ACHIEVEMENTS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.RESILIENCE]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.RESILIENCE_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ANALYTICAL_SKILLS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.ANALYTICAL_SKILLS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.TEAMWORK]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.TEAMWORK_COMMENT]: Yup.string().optional()
});

export const weaknessesInitialValues = {
  [FORM_NAMES.TECHNICAL_SKILL_GAPS]: 0,
  [FORM_NAMES.TECHNICAL_SKILL_GAPS_COMMENT]: "",
  [FORM_NAMES.ADAPTABILITY_TO_CHANGE]: 0,
  [FORM_NAMES.ADAPTABILITY_TO_CHANGE_COMMENT]: "",
  [FORM_NAMES.STRESS_MANAGEMENT]: 0,
  [FORM_NAMES.STRESS_MANAGEMENT_COMMENT]: "",
  [FORM_NAMES.PROBLEM_SOLVING_SKILLS]: 0,
  [FORM_NAMES.PROBLEM_SOLVING_SKILLS_COMMENT]: "",
  [FORM_NAMES.COMMUNICATION_CHALLENGES]: 0,
  [FORM_NAMES.COMMUNICATION_CHALLENGES_COMMENT]: "",
  [FORM_NAMES.TIME_MANAGEMENT_ISSUES]: 0,
  [FORM_NAMES.TIME_MANAGEMENT_ISSUES_COMMENT]: "",
  [FORM_NAMES.CLIENT_RELATIONSHIP_MANAGEMENT]: 0,
  [FORM_NAMES.CLIENT_RELATIONSHIP_MANAGEMENT_COMMENT]: "",
  [FORM_NAMES.UNDERSTANDING_LAW_PROCESSES]: 0,
  [FORM_NAMES.UNDERSTANDING_LAW_PROCESSES_COMMENT]: ""
};

export const weaknessesValidationSchema = Yup.object({
  [FORM_NAMES.TECHNICAL_SKILL_GAPS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.TECHNICAL_SKILL_GAPS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ADAPTABILITY_TO_CHANGE]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.ADAPTABILITY_TO_CHANGE_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.STRESS_MANAGEMENT]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.STRESS_MANAGEMENT_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.PROBLEM_SOLVING_SKILLS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.PROBLEM_SOLVING_SKILLS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.COMMUNICATION_CHALLENGES]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.COMMUNICATION_CHALLENGES_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.TIME_MANAGEMENT_ISSUES]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.TIME_MANAGEMENT_ISSUES_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.CLIENT_RELATIONSHIP_MANAGEMENT]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.CLIENT_RELATIONSHIP_MANAGEMENT_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.UNDERSTANDING_LAW_PROCESSES]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.UNDERSTANDING_LAW_PROCESSES_COMMENT]: Yup.string().optional()
});

export const potentialBiasesInitialValues = {
  [FORM_NAMES.AGE_BIAS]: 0,
  [FORM_NAMES.AGE_BIAS_COMMENT]: "",
  [FORM_NAMES.ETHNICITY_RACE_BIAS]: 0,
  [FORM_NAMES.ETHNICITY_RACE_BIAS_COMMENT]: "",
  [FORM_NAMES.INDUSTRY_BIAS]: 0,
  [FORM_NAMES.INDUSTRY_BIAS_COMMENT]: "",
  [FORM_NAMES.CULTURAL_BIAS]: 0,
  [FORM_NAMES.CULTURAL_BIAS_COMMENT]: "",
  [FORM_NAMES.TECHNOLOGY_BIAS]: 0,
  [FORM_NAMES.TECHNOLOGY_BIAS_COMMENT]: "",
  [FORM_NAMES.CONFIRMATION_BIAS]: 0,
  [FORM_NAMES.CONFIRMATION_BIAS_COMMENT]: "",
  [FORM_NAMES.GENDER_BIAS]: 0,
  [FORM_NAMES.GENDER_BIAS_COMMENT]: "",
  [FORM_NAMES.SOCIOECONOMIC_BIAS]: 0,
  [FORM_NAMES.SOCIOECONOMIC_BIAS_COMMENT]: "",
  [FORM_NAMES.EXPERIENCE_BIAS]: 0,
  [FORM_NAMES.EXPERIENCE_BIAS_COMMENT]: "",
  [FORM_NAMES.LANGUAGE_BIAS]: 0,
  [FORM_NAMES.LANGUAGE_BIAS_COMMENT]: "",
  [FORM_NAMES.POLITICAL_IDEOLOGY_BIAS]: 0,
  [FORM_NAMES.POLITICAL_IDEOLOGY_BIAS_COMMENT]: "",
  [FORM_NAMES.BEHAVIORAL_BIAS]: 0,
  [FORM_NAMES.BEHAVIORAL_BIAS_COMMENT]: ""
};

export const potentialBiasesValidationSchema = Yup.object({
  [FORM_NAMES.AGE_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.AGE_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.ETHNICITY_RACE_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.ETHNICITY_RACE_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.INDUSTRY_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.INDUSTRY_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.CULTURAL_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.CULTURAL_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.TECHNOLOGY_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.TECHNOLOGY_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.CONFIRMATION_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.CONFIRMATION_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.GENDER_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.GENDER_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.SOCIOECONOMIC_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.SOCIOECONOMIC_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.EXPERIENCE_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.EXPERIENCE_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.LANGUAGE_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.LANGUAGE_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.POLITICAL_IDEOLOGY_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.POLITICAL_IDEOLOGY_BIAS_COMMENT]: Yup.string().optional(),
  [FORM_NAMES.BEHAVIORAL_BIAS]: Yup.mixed<RatingValue>().oneOf([1, 2, 3, 4, 5, undefined]).optional(),
  [FORM_NAMES.BEHAVIORAL_BIAS_COMMENT]: Yup.string().optional()
});
