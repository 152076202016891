import { useFormik } from "formik";
import React, { useCallback } from "react";
import "../jurorProfile.scss";
import { useNavigate } from "react-router-dom";
import { BUTTON_TITLE, FORM_LABELS, FORM_NAMES, jurorProfileTabsData, TITLES } from "../../../../../utils/constants";
import { JurorEvaluationProps, RatingValue } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import RatingField from "../../../../RatingField";
import WrapperComponent from "../../../../WrapperComponent";
import { potentialBiasesInitialValues, potentialBiasesValidationSchema } from "../helper";

const PotentialBiases: React.FC<JurorEvaluationProps> = ({ handleTabChange }) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: potentialBiasesInitialValues,
    validationSchema: potentialBiasesValidationSchema,
    onSubmit: (values) => {
      console.log("Form data", values);
    }
  });

  const { values, setFieldValue, resetForm, handleSubmit } = formik;

  const handleRatingChange = useCallback(
    (field: string, value: RatingValue) => {
      setFieldValue(field, value);
    },
    [setFieldValue]
  );

  const handleCommentChange = useCallback(
    (field: string, comment: string) => {
      setFieldValue(field, comment);
    },
    [setFieldValue]
  );

  return (
    <div className='juror-evaluation-container'>
      <WrapperComponent title={TITLES.POTENTIAL_BIASES}>
        <form onSubmit={handleSubmit}>
          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.AGE_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.AGE_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.AGE_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.AGE_BIAS] as RatingValue}
              value={values[FORM_NAMES.AGE_BIAS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.GENDER_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.GENDER_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.GENDER_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.GENDER_BIAS] as RatingValue}
              value={values[FORM_NAMES.GENDER_BIAS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.ETHNICITY_RACE_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.ETHNICITY_RACE_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.ETHNICITY_RACE_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.ETHNICITY_RACE_BIAS] as RatingValue}
              value={values[FORM_NAMES.ETHNICITY_RACE_BIAS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.SOCIOECONOMIC_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.SOCIOECONOMIC_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.SOCIOECONOMIC_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.SOCIOECONOMIC_BIAS] as RatingValue}
              value={values[FORM_NAMES.SOCIOECONOMIC_BIAS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.INDUSTRY_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.INDUSTRY_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.INDUSTRY_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.INDUSTRY_BIAS] as RatingValue}
              value={values[FORM_NAMES.INDUSTRY_BIAS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.EXPERIENCE_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.EXPERIENCE_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.EXPERIENCE_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.EXPERIENCE_BIAS] as RatingValue}
              value={values[FORM_NAMES.EXPERIENCE_BIAS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.CULTURAL_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.CULTURAL_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.CULTURAL_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.CULTURAL_BIAS] as RatingValue}
              value={values[FORM_NAMES.CULTURAL_BIAS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.LANGUAGE_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.LANGUAGE_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.LANGUAGE_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.LANGUAGE_BIAS] as RatingValue}
              value={values[FORM_NAMES.LANGUAGE_BIAS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.TECHNOLOGY_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.TECHNOLOGY_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.TECHNOLOGY_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.TECHNOLOGY_BIAS] as RatingValue}
              value={values[FORM_NAMES.TECHNOLOGY_BIAS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.POLITICAL_IDEOLOGY_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.POLITICAL_IDEOLOGY_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.POLITICAL_IDEOLOGY_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.POLITICAL_IDEOLOGY_BIAS] as RatingValue}
              value={values[FORM_NAMES.POLITICAL_IDEOLOGY_BIAS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap'>
            <RatingField
              label={FORM_LABELS.CONFIRMATION_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.CONFIRMATION_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.CONFIRMATION_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.CONFIRMATION_BIAS] as RatingValue}
              value={values[FORM_NAMES.CONFIRMATION_BIAS_COMMENT]}
            />
            <RatingField
              label={FORM_LABELS.BEHAVIORAL_BIAS}
              onRatingChange={(value) => handleRatingChange(FORM_NAMES.BEHAVIORAL_BIAS, value)}
              onCommentChange={(comment) => handleCommentChange(FORM_NAMES.BEHAVIORAL_BIAS_COMMENT, comment)}
              initialRating={values[FORM_NAMES.BEHAVIORAL_BIAS] as RatingValue}
              value={values[FORM_NAMES.BEHAVIORAL_BIAS_COMMENT]}
            />
          </div>

          <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
            <ActionButton
              title={BUTTON_TITLE.SAVE}
              className='save-button'
              onClick={() => {
                navigate(-1);
                resetForm();
              }}
            />
            <ActionButton
              title={BUTTON_TITLE.CANCEL}
              onClick={() => {
                handleTabChange?.(jurorProfileTabsData[1]);
                resetForm();
              }}
              className='cancel-button'
            />
          </div>
        </form>
      </WrapperComponent>
    </div>
  );
};

export default PotentialBiases;
