/* eslint-disable @typescript-eslint/no-unsafe-function-type */
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { LayoutProp } from "./layout_types";
import Sidebar from "./Sidebar";
import "../../assets/scss/index.scss";
import "./styles.scss";
// Fontawesome
import "@fortawesome/fontawesome-pro/js/all";

/**
 * AdminPortalLayout component.
 *
 * @remarks
 * Admin Portal Layout
 *
 * @component AdminPortalLayout
 * @category Component
 */
const Layout = ({
  menuItems,
  profileMenuItems,
  username,
  logoImage,
  logoSmImage,
  hasDropdownMenu = false,
  dropdownChangeHandler,
  dropdownPlaceholder,
  dropdownValue,
  dropdownOptions,
  showWorldClock,
  handleSetting,
  hideSearch = false,
  hideTranslate = false,
  showGearIcon = true,
  showNotification = false,
  versionNumber,
  env,
  handleSearch,
  handleNotification,
  notifCount,
  notificationIconType,
  searchPlaceholder
}: LayoutProp) => {
  const [leftSideBarType, setLeftSideBarType] = useState("menu-opened");
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const handleSideMenu = () => {
    if (leftSideBarType === "menu-opened") {
      setLeftSideBarType("menu-closed");
    } else {
      setLeftSideBarType("menu-opened");
    }
  };

  return (
    <React.Fragment>
      <div
        id='layout-wrapper'
        className={`theme-${"anerva"} ${
          leftSideBarType === "menu-opened" ? "" : !isSmallScreen ? "vertical-collpsed" : ""
        } ${leftSideBarType === "menu-opened" && isSmallScreen ? "hide-menu" : ""}`}
      >
        <Header
          leftSideBarType={leftSideBarType}
          setLeftSideBarType={setLeftSideBarType}
          handleSetting={handleSetting}
          menuItems={profileMenuItems}
          username={username}
          hideSearch={hideSearch}
          showGearIcon={showGearIcon}
          showNotification={showNotification}
          hideTranslate={hideTranslate}
          handleSearch={handleSearch}
          handleNotification={handleNotification}
          notifCount={notifCount}
          notificationIconType={notificationIconType}
          searchPlaceholder={searchPlaceholder}
          handleSideMenu={handleSideMenu}
        />
        <Sidebar
          menuItems={menuItems}
          logoImage={logoImage}
          logoSmImage={logoSmImage}
          leftSideBarType={leftSideBarType}
          setLeftSideBarType={setLeftSideBarType}
          hasDropdownMenu={hasDropdownMenu}
          dropdownValue={dropdownValue}
          dropdownChangeHandler={dropdownChangeHandler}
          dropdownPlaceholder={dropdownPlaceholder}
          dropdownOptions={dropdownOptions}
          showWorldClock={showWorldClock}
          versionNumber={versionNumber}
          env={env}
        />
        <div className='main-content'>
          <div className='page-content'>
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
