import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer, ToastPosition } from "react-toastify";
import { Tooltip } from "react-tooltip";
import packageInfo from "../package.json";
import { AnervaLogo, AnervaSmLogo } from "./assets/images";
import Layout from "./components/AdminPortalLayout";
import { config, initializeConfig } from "./utils/config";
import socketService from "./utils/services/socketService";
import { ANERVA, FORM_PLACEHOLDERS, POSITIONS, STRINGS, THEME } from "./utils/constants";
import routes from "./utils/constants/routes";
import { routesConfig } from "./utils/constants/routesConfig";
import { menuItems, profileMenuItems } from "./utils/helpers";
import { PSLInstanceClass } from "./utils/pslInstance";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.scss";

const App = () => {
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);
  const PSL = PSLInstanceClass.getPSLInstance();

  useEffect(() => {
    const initApp = async () => {
      try {
        PSL.initLocalStorage();
        PSL.SetProjectEnvironment(process.env.REACT_APP_PSL || "anervaportal-staging");
        await initializeConfig();
        setIsInitialized(true);
        socketService.reconnect();
      } catch (error) {
        console.error("Failed to initialize app:", error);
        // Handle error (e.g., show error message to user)
      }
    };
    initApp();
  }, [PSL]);

  useEffect(() => {
    handleTheme();
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", () => {});
  }, []);

  const handleTheme = () => {
    document.body.classList.add("theme-" + "anerva");
  };

  const handleSearch = (str: string) => {
    str = str.replace("_task", "");
    navigate(`/page/search/${str}`);
  };

  console.log(
    `Anerva Portal
  Env: ${PSL.GetEnvironment()}
  PSL Endpoint: ${process.env.REACT_APP_PSL_ENDPOINT}
  Server URL: ${config.SERVER_URL}
  Is Initialized: ${isInitialized}`
  );

  if (!isInitialized) {
    return null;
  }
  return (
    <>
      <Routes>
        <Route
          path={routes.HOME}
          element={
            <Layout
              menuItems={menuItems}
              profileMenuItems={profileMenuItems}
              logoImage={AnervaLogo}
              logoSmImage={AnervaSmLogo}
              username={ANERVA}
              hideTranslate
              showWorldClock={false}
              versionNumber={packageInfo.version}
              env={PSL.GetEnvironment()}
              showGearIcon={false}
              showNotification
              notifCount={20}
              notificationIconType={STRINGS.REGULAR}
              searchPlaceholder={FORM_PLACEHOLDERS.SMART_SEARCH}
              handleSetting={() => {}}
              handleSearch={handleSearch}
            />
          }
        >
          {routesConfig.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
      </Routes>
      <Tooltip id={STRINGS.TOOL_TIP} />
      <ToastContainer
        position={POSITIONS.BOTTOM_RIGHT as ToastPosition}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={THEME.LIGHT}
      />
    </>
  );
};

export default App;
