import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AiSearch } from "../../assets/jsxIcons";
import ProfileIcon from "../../assets/jsxIcons/iconprofile";
import { ActionButton, BreadCrumbs } from "../../components";
import JuryProfileGenerationModal from "../../components/JuryProfileGenerationModal";
import NavigationCards from "../../components/NavigationCards";
import NavigationWrapper from "../../components/NavigationWrapper";
import JurorEvaluation from "../../components/Pages/JurorDetails/JurorEvaluation";
import JurorProfile from "../../components/Pages/JurorDetails/JurorProfile";
import { PersonalData } from "../../components/Pages/JurorDetails/PersonalData";
import { RootState } from "../../redux/types";
import { BUTTON_TITLE, navCards } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { NavCardPropTypes, TabPropTypes } from "../../utils/types";

export default function JurorDetails() {
  const [activeCard, setActiveCard] = useState<TabPropTypes>(navCards[0]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { selectedCase } = useSelector((state: RootState) => state.cases);

  function renderPageSection(activeTabId: number) {
    switch (activeTabId) {
      case 1:
        return <PersonalData setActiveCard={setActiveCard} />;
      case 2:
        return <JurorEvaluation setActiveCard={setActiveCard} />;
      case 3:
        return <JurorProfile setActiveCard={setActiveCard} />;
      default:
        return null;
    }
  }

  return (
    <div>
      <BreadCrumbs
        breadcrumbItems={[
          { text: "Manage Case", url: routes.MANAGE_CASE },
          { text: "Juror", url: routes.JUROR_DETAILS }
        ]}
      />

      <NavigationWrapper
        title={`Juror: ${selectedCase?.caseName || ""}`}
        headerChild={
          <ActionButton
            className={"bg-blue-light"}
            onClick={() => {
              setShowModal(true);
            }}
            title={BUTTON_TITLE.AI_PROFILE_GENERATE}
            icon={<AiSearch className='fill-white h-px-20 w-px-20' />}
          />
        }
      >
        <div>
          <NavigationCards
            tabs={navCards.map((item: NavCardPropTypes) => ({
              id: item.id,
              title: item.title,
              className: item.className,
              icon: item.id == 3 ? <ProfileIcon className='fill-blue-light' /> : <i className={item.iconClass}></i>
            }))}
            activeTab={activeCard}
            onTabChange={(tab) => setActiveCard(tab)}
          />
          {renderPageSection(activeCard.id)}
        </div>
        <JuryProfileGenerationModal showModal={showModal} onClose={() => setShowModal(false)} size='md' />
      </NavigationWrapper>
    </div>
  );
}
