/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { updateJuror } from "../../../../redux/slices/jurorSlice";
import { RootState } from "../../../../redux/types";
import { BUTTON_TITLE, CASE_TABS, TITLES, ClientColumns, MEMBER_TYPES, STRINGS } from "../../../../utils/constants";
import {
  ACTION_COLUMN_STYLE,
  USER_ID,
  newColumnFieldsInParticipants,
  dummyValuesForParticipantsDynamicFields
} from "../../../../utils/constants/columns";
import routes from "../../../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import { calculateNewRate } from "../../../../utils/helpers";
import useSocket from "../../../../utils/hooks/sockets";
import { CaseListData, Juror, Participant, ParticipantsProps, SelectedReasons } from "../../../../utils/types";
import { ActionButton } from "../../../ActionButton";
import AgGrid from "../../../AgGrid";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import RangeSlider from "../../../AgGrid/GridRangeSlider";
import PersonInfoModal from "../../../PersonInfoModal";
import StrikeJurorModal from "../../../StrikeJurorModal";
import { WrapperComponent } from "../../../WrapperComponent";
import CustomAddColumn from "./CustomAddColumn";
import { toggleField } from "./helpers";
import { setSelectedParticipant } from "../../../../redux/slices/participantsSlice";

const Participants: React.FC<ParticipantsProps> = ({ handleTabChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchedRef = useRef(false);
  const gridRef = useRef<any>(null);
  const { socket, socketEmit } = useSocket();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedPerson = null;
  const [selectedJurorForStrike, setSelectedJurorForStrike] = useState<Juror | null>(null);
  const [openStrikeModal, setOpenStrikeModal] = useState(false);
  const [gridKey, setGridKey] = useState(`grid-key-${Math.random()}`);
  const [activeCandidatesFields, setActiveCandidatesFields] = useState<{ [key: string]: boolean }>({
    ...newColumnFieldsInParticipants
  });
  const [activeWitnessesFields, setActiveWitnessesFields] = useState<{ [key: string]: boolean }>({
    ...newColumnFieldsInParticipants
  });
  const [activeExpertsFields, setActiveExpertsFields] = useState<{ [key: string]: boolean }>({
    ...newColumnFieldsInParticipants
  });
  const [columnDefsCandidates, setColumnDefsCandidates] = useState<ColDef[]>([
    ...ClientColumns.map((col) => ({ ...col, field: col.field ?? "" }))
  ]);
  const [columnDefsWitnesses, setColumnDefsWitnesses] = useState<ColDef[]>([
    ...ClientColumns.map((col) => ({ ...col, field: col.field ?? "" }))
  ]);
  const [columnDefsExperts, setColumnDefsExperts] = useState<ColDef[]>([
    ...ClientColumns.map((col) => ({ ...col, field: col.field ?? "" }))
  ]);

  const { candidates, witnesses, experts } = useSelector((state: RootState) => state.participants);
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const [stateCandidates, setStateCandidates] = useState(candidates);

  useEffect(() => {
    if (candidates) setStateCandidates(candidates);
  }, [candidates]);

  const handleToggleField = useCallback(
    (field: string, participantType: string) => {
      switch (participantType) {
        case STRINGS.Candidates:
          toggleField(
            field,
            participantType,
            activeCandidatesFields,
            setActiveCandidatesFields,
            columnDefsCandidates,
            setColumnDefsCandidates,
            dummyValuesForParticipantsDynamicFields
          );
          break;
        case STRINGS.Witnesses:
          toggleField(
            field,
            participantType,
            activeWitnessesFields,
            setActiveWitnessesFields,
            columnDefsWitnesses,
            setColumnDefsWitnesses,
            dummyValuesForParticipantsDynamicFields
          );
          break;
        case STRINGS.Experts:
          toggleField(
            field,
            participantType,
            activeExpertsFields,
            setActiveExpertsFields,
            columnDefsExperts,
            setColumnDefsExperts,
            dummyValuesForParticipantsDynamicFields
          );
          break;
        default:
          break;
      }

      setGridKey(`grid-key-${Math.random()}`); // Regenerate gridKey to force re-render
    },
    [
      activeCandidatesFields,
      activeExpertsFields,
      activeWitnessesFields,
      columnDefsCandidates,
      columnDefsExperts,
      columnDefsWitnesses
    ]
  );

  const fetchAllParticipantsFromServer = () => {
    if (fetchedRef.current) return;
    if (socket) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        {
          type: SOCKET_EVENTS.FETCH_PARTICIPANT_NON_TRIAL,
          eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT,
          userId: USER_ID,
          caseId: selectedCase?.id,
          requestId: uuidv4()
        },
        () => {
          fetchedRef.current = true;
        }
      );
    }
  };

  useEffect(() => {
    fetchAllParticipantsFromServer();
    return () => {
      socket?.off(SOCKET_EVENTS.PARTICIPANT_DATA_NON_TRIAL);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, dispatch]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleDelete = (rowData: Participant) => {
    handleRemovePerson(rowData.id, USER_ID, selectedCase?.id);
  };

  const handleOpenStrikeModal = useCallback((juror: Juror) => {
    setSelectedJurorForStrike(juror);
    setOpenStrikeModal(true);
  }, []);

  const handleStrikeSubmit = useCallback(
    (data: { selectedReasons: SelectedReasons }) => {
      if (selectedJurorForStrike) {
        const currentRate = selectedJurorForStrike?.jurorRate || "0";
        const newRate = calculateNewRate(currentRate, data.selectedReasons);
        const updatedJurors = { ...selectedJurorForStrike, jurorRate: `${newRate}%` };
        dispatch(updateJuror(updatedJurors));
        setOpenStrikeModal(false);
        setSelectedJurorForStrike(null);
      }
    },
    [dispatch, selectedJurorForStrike]
  );

  const handleRemovePerson = (
    participantId: string | undefined,
    userId: string | undefined,
    caseId: string | undefined
  ) => {
    const payload = {
      participantId,
      requestId: uuidv4(),
      type: SOCKET_EVENTS.DELETE_PARTICIPANT,
      eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT,
      userId,
      caseId
    };
    try {
      if (socketEmit)
        socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, ({ success, message }) => {
          if (success) toast.success(message);
          else toast.error(message);
        });
    } catch (error) {
      if (error instanceof Error) console.error("addMember error: ", error.message);
    }
  };

  const handleAddNew = (path: string, caseData: CaseListData | undefined, memberType?: string) => {
    navigate(path, { state: { caseData, memberType } });
  };

  const candidatesClientColumnsList: ColDef[] = [
    ...columnDefsCandidates,
    {
      field: "rate",
      headerName: "Rate",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      minWidth: 140,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellRendererFramework: (params: ICellRendererParams) => (
        <RangeSlider
          value={(params?.data?.caseParticipants[0]?.participantRatings[0]?.rating ?? 0) / 0.05}
          min={0}
          max={100}
          onClick={() => handleOpenStrikeModal(params.data)}
        />
      )
    },
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      flex: 1,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => {
            // handleViewPersonInfo(params.data)
            navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, { state: { participantData: params.data } });
          }}
          onDelete={() => handleDelete(params.data as Participant)}
          onEdit={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.EDIT_NEW_CANDIDATE, { state: { participantData: params.data } });
          }}
        />
      )
    },
    {
      field: "add",
      headerName: "",
      minWidth: 70,
      headerComponentFramework: () => (
        <CustomAddColumn
          onToggleField={(field) => handleToggleField(field, "candidates")}
          activeFields={activeCandidatesFields}
        />
      ),
      headerClass: "custom-header"
    }
  ];

  const witnessesClientColumnsList: ColDef[] = [
    ...columnDefsWitnesses,
    {
      field: "rate",
      headerName: "Rate",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      minWidth: 140,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellRendererFramework: (params: ICellRendererParams) => (
        <RangeSlider
          value={(params?.data?.caseParticipants[0]?.participantRatings[0]?.rating ?? 0) / 0.05}
          min={0}
          max={100}
          onClick={() => handleOpenStrikeModal(params.data)}
        />
      )
    },
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      flex: 1,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => {
            // handleViewPersonInfo(params.data)
            navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, { state: { participantData: params.data } });
          }}
          onDelete={() => handleDelete(params.data as Participant)}
          onEdit={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.EDIT_NEW_WITNESS, { state: { participantData: params.data } });
          }}
        />
      )
    },

    {
      field: "add",
      headerName: "",
      minWidth: 70,
      headerComponentFramework: () => (
        <CustomAddColumn
          onToggleField={(field) => handleToggleField(field, "witnesses")}
          activeFields={activeWitnessesFields}
        />
      ),
      headerClass: "custom-header"
    }
  ];

  const expertsClientColumnsList: ColDef[] = [
    ...columnDefsExperts,
    {
      field: "rate",
      headerName: "Rate",
      sortable: true,
      unSortIcon: true,
      flex: 1,
      minWidth: 140,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cellRendererFramework: (params: ICellRendererParams) => (
        <RangeSlider
          value={(params?.data?.caseParticipants[0]?.participantRatings[0]?.rating ?? 0) / 0.05}
          min={0}
          max={100}
          onClick={() => handleOpenStrikeModal(params.data)}
        />
      )
    },
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      flex: 1,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onView={() => {
            // handleViewPersonInfo(params.data)
            navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, { state: { participantData: params.data } });
          }}
          onDelete={() => handleDelete(params.data as Participant)}
          onEdit={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.EDIT_NEW_EXPERTS, { state: { participantData: params.data } });
          }}
        />
      )
    },
    {
      field: "add",
      headerName: "",
      minWidth: 70,
      headerComponentFramework: () => (
        <CustomAddColumn
          onToggleField={(field) => handleToggleField(field, "experts")}
          activeFields={activeExpertsFields}
        />
      ),
      headerClass: "custom-header"
    }
  ];

  return (
    <div className='d-flex h-100 flex-column'>
      <>
        <WrapperComponent
          title={TITLES.CANDIDATES}
          customClass='mb-2'
          buttonText={BUTTON_TITLE.ADD_NEW_CANDIDATE}
          onClick={() => handleAddNew(routes.ADD_NEW_CANDIDATE, selectedCase || undefined, MEMBER_TYPES[2])}
        >
          <div style={{ height: "350px", width: "100%" }}>
            <AgGrid
              key={gridKey}
              columnDefs={candidatesClientColumnsList}
              rowData={stateCandidates}
              onGridReady={(params) => {
                gridRef.current = params;
                params.api.sizeColumnsToFit();
              }}
              animateRows
            />
          </div>
        </WrapperComponent>
        <WrapperComponent
          title={TITLES.WITNESSES}
          customClass='mt-2 mb-2'
          buttonText={BUTTON_TITLE.ADD_NEW_WITNESS}
          onClick={() => handleAddNew(routes.ADD_NEW_WITNESS, selectedCase || undefined, MEMBER_TYPES[0])}
        >
          <div style={{ height: "350px", width: "100%" }}>
            <AgGrid key={gridKey} columnDefs={witnessesClientColumnsList} rowData={witnesses} animateRows />
          </div>
        </WrapperComponent>
        <WrapperComponent
          title={TITLES.EXPERTS}
          customClass='mt-2 mb-2'
          buttonText={BUTTON_TITLE.ADD_NEW_EXPERT}
          onClick={() => handleAddNew(routes.ADD_NEW_EXPERTS, selectedCase || undefined, MEMBER_TYPES[1])}
        >
          <div style={{ height: "350px", width: "100%" }}>
            <AgGrid key={gridKey} columnDefs={expertsClientColumnsList} rowData={experts} animateRows />
          </div>
        </WrapperComponent>
      </>
      <ActionButton
        className='save-button continue-button mt-2 mb-2'
        onClick={() => {
          handleTabChange?.(CASE_TABS.TRIAL_TEAM);
        }}
        title={BUTTON_TITLE.CONTINUE}
      />
      {isModalOpen && <PersonInfoModal isOpen={isModalOpen} toggle={toggleModal} data={selectedPerson} />}
      <StrikeJurorModal
        isOpen={openStrikeModal}
        onClose={() => {
          setOpenStrikeModal(false);
          setSelectedJurorForStrike(null);
        }}
        onSubmit={handleStrikeSubmit}
        currentParticipant={selectedJurorForStrike}
      />
    </div>
  );
};

export default Participants;
