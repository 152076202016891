import { Form, useFormikContext } from "formik";
import React from "react";
import {
  BUTTON_TITLE,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  personalDataPageTabs
} from "../../../../../utils/constants";
import { PersonalDataProps, PersonalPreferencesFormValueProps } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import TextInput from "../../../../TextInput";

export const PersonalPreferencesForm: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  const { values, errors, handleChange, handleSubmit, resetForm } =
    useFormikContext<PersonalPreferencesFormValueProps>();
  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.INTERESTS_HOBBIES}
          name={FORM_NAMES.INTERESTS_HOBBIES}
          errorMessage={errors?.interestsHobbies}
          value={values?.interestsHobbies}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.FAVORITE_BOOKS_MOVIES_TV_SHOWS}
          name={FORM_NAMES.FAVORITE_BOOKS_MOVIES_TV_SHOWS}
          errorMessage={errors?.favoriteBooksMoviesTvShows}
          value={values?.favoriteBooksMoviesTvShows}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.MUSIC_PREFERENCES}
          name={FORM_NAMES.MUSIC_PREFERENCES}
          errorMessage={errors?.musicPreferences}
          value={values?.musicPreferences}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.FOOD_PREFERENCES}
          name={FORM_NAMES.FOOD_PREFERENCES}
          errorMessage={errors?.foodPreferences}
          value={values?.foodPreferences}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.TRAVEL_PREFERENCES}
          name={FORM_NAMES.TRAVEL_PREFERENCES}
          errorMessage={errors?.travelPreferences}
          value={values?.travelPreferences}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.PETS}
          name={FORM_NAMES.PETS}
          errorMessage={errors?.pets}
          value={values?.pets}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.OUTDOOR_ACTIVITIES}
          name={FORM_NAMES.OUTDOOR_ACTIVITIES}
          errorMessage={errors?.outdoorActivities}
          value={values?.outdoorActivities}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.CHARITABLE_CONTRIBUTIONS}
          name={FORM_NAMES.CHARITABLE_CONTRIBUTIONS}
          errorMessage={errors?.charitableContributions}
          value={values?.charitableContributions}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.RELIGIOUS_AFFILIATIONS}
          name={FORM_NAMES.RELIGIOUS_AFFILIATIONS}
          errorMessage={errors?.religiousAffiliations}
          value={values?.religiousAffiliations}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.ORGANIZATIONAL_AFFILIATIONS}
          name={FORM_NAMES.ORGANIZATIONAL_AFFILIATIONS}
          errorMessage={errors?.organizationalAffiliations}
          value={values?.organizationalAffiliations}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex gap-2'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[5]);
            resetForm();
          }}
          className='bg-primary-light'
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[3]);
            resetForm();
          }}
          className='bg-red'
        />
      </div>
    </Form>
  );
};
