import React, { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AiSearch } from "../../assets/jsxIcons";
import { ActionButton, BreadCrumbs } from "../../components";
import JuryProfileGenerationModal from "../../components/JuryProfileGenerationModal";
import NavigationWrapper from "../../components/NavigationWrapper";
import EditCandidate from "../../components/Pages/ManageCase/CustomizableEditCandidate";
import { setActiveTab } from "../../redux/slices/manageCaseSlice";
import { BUTTON_TITLE, CASE_TABS, editTitleMap } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../utils/constants/socketEvents";
import useSocket from "../../utils/hooks/sockets";
import "./styles.scss";

const CustomizableEditParticipant: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const pathSegment = location.pathname.split("/").pop();
  const title = editTitleMap[pathSegment as keyof typeof editTitleMap] || "Edit Participant";
  const route = useLocation();
  const personData = route.state;
  const { socket, socketEmit } = useSocket();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addMemberAsTeam = useCallback(() => {
    const payload = {
      userId: personData?.participantData?.userId?.id,
      type: SOCKET_EVENTS.ADD_CASE_TRIAL_PARTICIPANT,
      eventType: SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT,
      caseParticipantId: personData?.participantData?.caseParticipants[0]?.id,
      requestId: uuidv4()
    };
    if (socket) {
      socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, (data) => {
        if (data.requestId === payload.requestId) {
          navigate(routes.MANAGE_CASE);
          dispatch(setActiveTab(CASE_TABS.TRIAL_TEAM));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData, socketEmit, socket]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className='d-flex flex-column'>
        <BreadCrumbs
          breadcrumbItems={[
            {
              text: "Manage Case",
              url: routes.MANAGE_CASE
            },
            { text: "Case Participants", url: location.pathname }
          ]}
        />
        <NavigationWrapper
          title={title}
          headerChild={
            <div className='header-child-container'>
              <ActionButton
                className={"bg-primary-light"}
                onClick={addMemberAsTeam}
                title={BUTTON_TITLE.ADD_AS_A_TEAM}
                icon={<i className='fa-regular fa-user-plus'></i>}
              />
              <ActionButton
                className={"bg-blue-light"}
                onClick={() => {
                  setShowModal(true);
                }}
                title={BUTTON_TITLE.AI_PROFILE_GENERATE}
                icon={<AiSearch className='fill-white h-px-20 w-px-20' />}
              />
            </div>
          }
        >
          <div className='scrollable-container'>
            <EditCandidate data={{ personData: personData }} />
          </div>
          <JuryProfileGenerationModal showModal={showModal} onClose={() => setShowModal(false)} size='md' />
        </NavigationWrapper>
      </div>
    </DndProvider>
  );
};

export default CustomizableEditParticipant;
