import { Form, useFormikContext } from "formik";
import {
  BUTTON_TITLE,
  economicStatusOptions,
  educationLevelOptions,
  FIELD_TYPES,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  homeOwnershipStatusOptions,
  personalDataPageTabs
} from "../../../../../utils/constants";
import { DemographicsFormValueProps, PersonalDataProps } from "../../../../../utils/types";
import ActionButton from "../../../../ActionButton";
import SelectComponent from "../../../../SelectComponent";
import TextInput from "../../../../TextInput";

export const DemographicInformationForm: React.FC<PersonalDataProps> = ({ handleTabChange }) => {
  const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm } =
    useFormikContext<DemographicsFormValueProps>();
  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.ETHNICITY}
          name={FORM_NAMES.ETHNICITY}
          errorMessage={errors?.ethnicity}
          value={values?.ethnicity}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <SelectComponent
          label={FORM_LABELS.ECONOMIC_STATUS}
          name={FORM_NAMES.ECONOMIC_STATUS}
          items={economicStatusOptions}
          value={values.economicStatus}
          onChange={(e) => setFieldValue(FORM_NAMES.ECONOMIC_STATUS, e.target.value)}
          errorMessage={errors.economicStatus}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <TextInput
          label={FORM_LABELS.OCCUPATION}
          name={FORM_NAMES.OCCUPATION}
          errorMessage={errors?.occupation}
          value={values?.occupation}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.WORKPLACE}
          name={FORM_NAMES.WORKPLACE}
          errorMessage={errors?.workplace}
          value={values?.workplace}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap mt-2'>
        <SelectComponent
          label={FORM_LABELS.EDUCATION_LEVEL}
          name={FORM_NAMES.EDUCATION_LEVEL}
          items={educationLevelOptions}
          value={values.educationLevel}
          onChange={(e) => setFieldValue(FORM_NAMES.EDUCATION_LEVEL, e.target.value)}
          errorMessage={errors.educationLevel}
        />
        <TextInput
          label={FORM_LABELS.ACADEMIC_RECORDS}
          name={FORM_NAMES.ACADEMIC_RECORDS}
          errorMessage={errors?.academic_records}
          value={values?.academic_records}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <p className='my-2 color-primary-light font-size-15'>HOUSEHOLD INFORMATION</p>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
        <div className='w-50'>
          <TextInput
            label={FORM_LABELS.ESTIMATED_HOUSEHOLD_INCOME}
            name={FORM_NAMES.ESTIMATED_HOUSEHOLD_INCOME}
            value={values.estimatedHouseholdIncome}
            errorMessage={errors.estimatedHouseholdIncome}
            onChange={handleChange}
            parentClassName='w-85'
            type={FIELD_TYPES.CURRENCY}
            currencyText={"per year"}
          />
        </div>
        <div className='w-50'>
          <TextInput
            label={FORM_LABELS.ESTIMATED_NET_WORTH}
            name={FORM_NAMES.ESTIMATED_NET_WORTH}
            value={values.estimatedNetWorth}
            onChange={handleChange}
            errorMessage={errors.estimatedNetWorth}
            parentClassName='w-90'
            type={FIELD_TYPES.CURRENCY}
            currencyText={"M"}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap w-100'>
        <TextInput
          label={FORM_LABELS.LENGTH_OF_RESIDENCE}
          name={FORM_NAMES.LENGTH_OF_RESIDENCE}
          value={values.lengthOfResidence}
          errorMessage={errors.lengthOfResidence}
          onChange={handleChange}
        />
        <SelectComponent
          label={FORM_LABELS.HOME_OWNERSHIP_STATUS}
          name={FORM_NAMES.HOME_OWNERSHIP_STATUS}
          items={homeOwnershipStatusOptions}
          value={values.homeOwnershipStatus}
          onChange={(e) => setFieldValue(FORM_NAMES.HOME_OWNERSHIP_STATUS, e.target.value)}
          errorMessage={errors.homeOwnershipStatus}
        />
      </div>
      <div className='d-flex gap-2'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[2]);
            resetForm();
          }}
          className='bg-primary-light'
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            handleTabChange?.(personalDataPageTabs[0]);
            resetForm();
          }}
          className='bg-red'
        />
      </div>
    </Form>
  );
};
